import React, {Suspense} from 'react'
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './components/styles/Global.styled'
import {Container} from './components/styles/Container.styled'

//GENERAL COMPONENTS (sign-in, sign-up, forgetpasword)
import SignUp from './pages/signup/SignUp'
import SignIn from './pages/signin/SignIn'
import ForgetPassword from './pages/forgotpassword/ForgetPassword'

//SIDEBAR COMPONENTS
import Dashboard from './pages/dashboard/Dashboard'
import Home from './components/dashboard-home/Home'
import Users from './components/users/Users'
import Devices from './components/devices/Devices'
import Plans from './components/plans/Plans';
import DeviceManagement from './components/deviceManagement/DeviceManagement';
import AccountManagement from './components/accountManagement/AccountManagement';
import Reports from './components/reports/Reports';
import NewUser from './components/new-user/NewUser';
import NewDevice from './components/new-device/NewDevice';
import NewPlan from './components/new-plan/NewPlan';
import NewDeviceManagement from './components/new-deviceManagement/NewDeviceManagement'
import {BrowserRouter as Router, HashRouter, Routes, Route} from 'react-router-dom'

//ACCOUNT-MANAGEMENT TAB-ITEM COMPONENTS
import Branding from './components/accountManagement/tabItems/Branding';
import Design from './components/accountManagement/tabItems/Design';
import Services from './components/accountManagement/tabItems/Services';
import UserDefault from './components/accountManagement/tabItems/UserDefault';
import Messaging from './components/accountManagement/tabItems/Messaging';
import Settings from './components/accountManagement/tabItems/Settings';
import Wallet from './components/accountManagement/tabItems/Wallet';
import ManageSubscription from './components/accountManagement/tabItems/ManageSubscription';
import EditUser from './components/new-user/EditUser';


const theme = {
  colors: {
    header: '#ebfbff',
    body: '#fff',
    footer: '#003333',
  },
  mobile: '768px',
}
function App() {
  return (
    <Suspense fallback={"loading..."}>
      <ThemeProvider theme={theme}>
          <Container>
            <GlobalStyles/>
            <Router>
              <Routes>
                <Route path="/" element={<SignUp/>}/>
                <Route path="/login" element={<SignIn/>}/>
                <Route path="/forgetpassword" element={<ForgetPassword/>}/>
                <Route path='dashboard' element={<Dashboard/>}>
                  <Route path='/dashboard' element={<Home/>}/>
                  <Route path="user" element={<Users/>}/>
                  <Route path="device" element={<Devices/>}/>
                  <Route path="plan" element={<Plans/>}/>
                  <Route path="device-management" element={<DeviceManagement/>}/>
                  <Route path="account-management" element={<AccountManagement/>}>
                    <Route index path="brand" element={<Branding/>}/>
                    <Route path="design" element={<Design/>}/>
                    <Route path="services" element={<Services/>}/>
                    <Route path="user" element={<UserDefault/>}/>
                    <Route path="message" element={<Messaging/>}/>
                    <Route path="setting" element={<Settings/>}/>
                    <Route path="wallet" element={<Wallet/>}/>
                    <Route path="manage" element={<ManageSubscription/>}/>
                  </Route>
                  <Route path="report" element={<Reports/>}/>
                  <Route path="new-user" element={<NewUser/>}/>
                  <Route path="new-device" element={<NewDevice/>}/>
                  <Route path='edit-user' element={<EditUser/>}/>
                  <Route path="new-plan" element={<NewPlan/>}/>
                  <Route path="new-deviceManage" element={<NewDeviceManagement/>}/>
                </Route>
              </Routes>
            </Router>
          </Container>
        </ThemeProvider>
    </Suspense>
  );
}

export default App;
