import React, {useState, useRef}from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import DeleteModal from '../../modal/DeleteModal';
import Modal from '../../modal/Modal';
import { useDispatch } from 'react-redux';
import {PopContainer, Wrapper, PopItem} from './pop.styled'
import { setItem } from '../../../features/redux/modalReducer';
import { setId, setModalItem, toggleModalItem} from '../../../features/redux/modalItem';


const Popup = ({id}) => {
  const dash = useLocation().pathname.split('/')[2];
  const modalRef = useRef(null);
  const navigate = useNavigate();
  //const [open, setOpen]= useState(true);
  const dispatch = useDispatch();

  const handleMouseLeave = (e)=>{
      e.preventDefault()
  }

  const handleClick = (val)=>{
    //e.preventDefault();
    dispatch(setId(id));
    dispatch(setModalItem(val));
    dispatch(toggleModalItem());
  }
  return (
    <PopContainer ref={modalRef} onMouseLeave={handleMouseLeave} toggle={dash}>
        <Wrapper>
            <PopItem colr="#464F60" 
              onClick={()=> navigate('/dashboard/edit-user')}
            >
              Edit
            </PopItem>
            <PopItem colr="407BFF" onClick={()=>handleClick('exportUser')}>
              Export
            </PopItem>
            <PopItem colr="E85B81" onClick={()=>handleClick('deactivate')}>
              Deactivate
            </PopItem>
            <PopItem colr="FF0000" onClick={()=>handleClick('delete')}>
              Delete
            </PopItem>
        </Wrapper>
    </PopContainer>
  )
}

export default Popup