import React, {useState} from 'react'
import Divider from '../divider/Divider'
import {
    Container, 
    Form, 
    FormItem, 
    ItemContainer,
    User, 
    Input,
    DeviceBtn
  } from '../form/dashboardFormContent.styled'
const NewDeviceForm = ({
    users, 
    deviceType, 
    model, 
    handleDevice,
    handleSubmit
}) => {
    
  return (
    <Form>
        <Divider title="Add New Device" width="45.5"/>
        <FormItem>
            <ItemContainer>
                <select name="user" onChange={handleDevice}>
                    <option>Select the device owner</option>
                    {
                    users?.map((d)=> <option key={d._id}>{d.contact.company}</option>)
                    }
                </select>
            </ItemContainer>
            <ItemContainer>
                <Input type="text" 
                    placeholder="Device Name" 
                    name="deviceName" 
                    onChange={handleDevice}
                />
            </ItemContainer>
            <ItemContainer>
                <Input type="text" 
                    placeholder="IMEI/ID" 
                    name="imei" 
                    onChange={handleDevice}
                />
            </ItemContainer>
            <ItemContainer>
                <select name="deviceType" onChange={handleDevice}>
                <option>Device Type</option>
                {
                    deviceType !== undefined
                    ? deviceType?.map(({title, _id})=> <option value={title} key={_id}>{title}</option>) 
                    : <option>No value</option>
                }
                </select>
            </ItemContainer>
            <ItemContainer>
                <select name="deviceModel" onChange={handleDevice}>
                    <option>Select the device model</option>
                    {
                        model?.map((d)=> <option key={d._id}>{d.deviceModel}</option>)
                    }
                </select>
            </ItemContainer>
            <ItemContainer>
                <Input type="text" 
                    placeholder="SIM Nunmber"  
                    name="simiccid" 
                    onChange={handleDevice}
                />
            </ItemContainer>
            <ItemContainer>
                <Input type="text" 
                    placeholder="Phone Number" 
                    name="phone" 
                    onChange={handleDevice}
                />
            </ItemContainer>
           
            <ItemContainer>
                <User>
                    <input type="checkbox"/>
                    <span>Auto-Configure</span>
                </User>
            </ItemContainer>
        </FormItem>
        <DeviceBtn onClick={handleSubmit}>
            Create New Device
        </DeviceBtn>
    </Form>
  )
}

export default NewDeviceForm