import styled from 'styled-components'
import { device } from '../styles/responsiveDevices'


export const Container = styled.div`
    flex: 2;

    @media ${device.mobileL}{
        display: none;
    };

    @media ${device.mobileD}{
        display: none;
    };
`
export const Image = styled.div`
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media ${device.tablet}{
        width: 480px;
        height: 70vh;
    };

`