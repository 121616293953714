import styled from 'styled-components'


export const BackDrop = styled.div`
    display: grid;
    place-items: center;
    width: 116%;
    height: 100vh;
    max-height: 100vh;
    position: fixed;
    background-color: rgba(51, 42, 42, 0.5);
    z-index: 999;
    top:0;
    transform: translate(-20%, -2%);
    padding-right:8rem;
    /* padding-top: 6rem; */
`

export const ModalWrapper = styled.div`
    height: 70%;
    width: 70%;
    flex-direction: column;
    background:#fff;
`
export const Container = styled.div`
    display: flex;

    .modal-title{
        width: 96%;
        padding: 10px 0;
        padding-left: 2rem;
        background: var(--item-bg);
        color: #fff;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: var(--letter-spacing);
    }
    .modal-close{
        display: grid;
        place-items: center;
        background: rgba(236, 28, 36, 0.15);
        aspect-ratio: 1;
        width: 4%;
    }
`


export const ModalContent = styled.div`
    /* max-height: 100%; */
    /* height: 100%; */
   
`