import { ElevatorOutlined } from '@mui/icons-material'
import { NavLink, useLocation} from 'react-router-dom'
import { 
    AccountManageHeader, 
    AccountManageTab, 
    Text,
    FirstTabItem,
    LastTabItem
} from '../form/dasboardForm.styled'

const tabs = ["Branding", "Design", "Service"]
const AccountTab = () => {
    let pam = useLocation()
    console.log(pam, "pam")
  return (
    <AccountManageHeader>
        <FirstTabItem>
            <NavLink to="brand" className={({isActive})=> isActive ? "active" : "inactive"}>
             <ElevatorOutlined className="tab__icon"/>
             <Text>Branding</Text>
            </NavLink>
        </FirstTabItem>
        <AccountManageTab>
           <NavLink to="design" className={({isActive})=> isActive ? "active" : "inactive"}>
            <ElevatorOutlined className="tab__icon"/>
            <Text>Design</Text>
           </NavLink>
        </AccountManageTab>
        <AccountManageTab>
            <NavLink to="services" className={({isActive})=> isActive ? "active" : "inactive"}>
             <ElevatorOutlined className="tab__icon"/>
             <Text>Service Preferences</Text>
            </NavLink>
        </AccountManageTab>
        <AccountManageTab>
           <NavLink to="user" className={({isActive})=> isActive ? "active" : "inactive"}>
            <ElevatorOutlined className="tab__icon"/>
            <Text>User Default</Text>
           </NavLink>
        </AccountManageTab>
        <AccountManageTab>
           <NavLink to="message" className={({isActive})=> isActive ? "active" : "inactive"}>
            <ElevatorOutlined className="tab__icon"/>
            <Text>Messaging</Text>
           </NavLink>
        </AccountManageTab>
        <AccountManageTab >
            <NavLink to="setting" className={({isActive})=> isActive ? "active" : "inactive"}>
             <ElevatorOutlined className="tab__icon"/>
             <Text>Setting</Text>
            </NavLink>
        </AccountManageTab>
        <AccountManageTab>
            <NavLink to="wallet" className={({isActive})=> isActive ? "active" : "inactive"}>
             <ElevatorOutlined className="tab__icon"/>
             <Text>Wallet</Text>
            </NavLink>
        </AccountManageTab>
        <LastTabItem>
            <NavLink to="manage" className={({isActive})=> isActive ? "active" : "inactive"}>
             <ElevatorOutlined className="tab__icon"/>
             <Text>Manage Subscriptions</Text>
            </NavLink>
        </LastTabItem>
    </AccountManageHeader>
  )
}

export default AccountTab