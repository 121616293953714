import { StyledTable, THead, TBody, TFoot, TH, TR, TD } from './tableStyle';

const Table = ({ children, ...rest }) => {
    return <StyledTable {...rest}>{children}</StyledTable>;
};
  
Table.Head = ({ children, ...rest }) => {
    return <THead {...rest}>{children}</THead>;
};
  
Table.Body = ({ children, ...rest }) => {
    return <TBody {...rest}>{children}</TBody>;
};
  
Table.Foot = ({ children, ...rest }) => {
    return <TFoot {...rest}>{children}</TFoot>;
};
  
Table.TH = ({ children, ...rest }) => {
    return <TH {...rest}>{children}</TH>;
};
  
Table.TR = ({ children, ...rest }) => {
    return <TR {...rest}>{children}</TR>;
};
  
Table.TD = ({ children, ...rest }) => {
    return <TD {...rest}>{children}</TD>;
};

export default Table
  