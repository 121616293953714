import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { client, TokenHeader } from '../api/axiosInstance';


//Use the client hooks to get the initial data from the server
//Position the url according to the return data from the client
// Usage: const {firstCall, secondCall} = useClient(firstUrl, secondUrl)
const useClient = (firstUrl, secondUrl) => {

  const [firstCall, setFirstCall] = useState([]);
  const [err, setErr] = useState('')
  const [secondCall, setSecondCall] = useState([]);
  const [data, setData] = useState('loading')
  
 
 
    console.log("first", firstCall)
    console.log("second", secondCall)

   //Get the devices, and plan addons from the server
  useEffect(()=>{
   
    let request = client(TokenHeader)
    const fetchData = async ()=>{
      try{
        const [firstData, secondData] = await Promise.all([
          request.get(process.env.REACT_APP_ENTERPRISE_URL + firstUrl),
          request.get(process.env.REACT_APP_ENTERPRISE_URL + secondUrl)
        ])
        setFirstCall(firstData?.data);
        setSecondCall(secondData?.data);
        setData('success');
      }catch(e){
        setErr(e.message);
        setData("failed")
      }
     
    }

    fetchData();
  }, [firstUrl, secondUrl]);


  return {firstCall, secondCall, data}

}

export default useClient