import {useEffect, useState} from 'react'
import { client, TokenHeader } from '../api/axiosInstance';
const useMultipleClients = (firstUrl, secondUrl, thirdUrl) => {
    const [firstCall, setFirstCall] = useState([]);
    const [err, setErr] = useState('')
    const [secondCall, setSecondCall] = useState([]);
    const [thirdCall, setThirdCall] = useState([]);
    const [data, setData] = useState('loading')
   
   
      console.log("first", firstCall)
      console.log("second", secondCall)
      console.log("third", thirdCall)
  
     //Get the devices, and plan addons from the server
    useEffect(()=>{
     
      let request = client(TokenHeader)
      const fetchData = async ()=>{
        try{
          const [firstData, secondData, thirdData] = await Promise.all([
            request.get(process.env.REACT_APP_ENTERPRISE_URL + firstUrl),
            request.get(process.env.REACT_APP_ENTERPRISE_URL + secondUrl),
            request.get(process.env.REACT_APP_ENTERPRISE_URL + thirdUrl)
          ])
          setFirstCall(firstData?.data);
          setSecondCall(secondData?.data);
          setThirdCall(thirdData?.data)
          setData('success');
        }catch(e){
          setErr(e.message);
          setData("failed")
        }
       
      }
  
      fetchData();
    }, [firstUrl, secondUrl, thirdUrl]);
  
  
    return {firstCall, secondCall, thirdCall, data}
}

export default useMultipleClients