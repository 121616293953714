import { createGlobalStyle } from 'styled-components'
import {device} from '../styles/responsiveDevices'

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');;
  * {
    box-sizing: border-box;
  }
  body {
    background: ${({ theme }) => theme.colors.body};
    color: hsl(192, 100%, 9%);
    font-family: 'Roboto', sans-serif;
    font-size: 1.0rem;
    margin: 0;
  }
  p {
    opacity: 0.6;
    line-height: 1.5;
  }
  
  .menu{
    display: block;
    padding: 8px;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    border: none;
    background: #E5E5E5;
  }

  @media ${device.tablet}{
    .menu{
      width: 15.7rem;
    }
  }

  .menu-flags-button{
    border: none;
    padding: 0;
  }

  .link{
    text-decoration: none;
    color: #008000;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .active{
   span{
     color:  #4D9200;
   }
 }

 .inactive{
   span{
     color: #838191;
   }
 }
 
 .sign-form{
    text-decoration: none;
    color: #008000;
 }

 .userIcon{
   font-size: 1rem !important;
 }
`

export default GlobalStyles