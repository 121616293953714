import React from 'react'
import Divider from '../divider/Divider'
import {
    Form, 
    FormItem,  
    User, 
    Input, 
    Button,
    NewUserItem
} from '../form/dashboardFormContent.styled'
import { Toaster } from 'react-hot-toast'
import { ModalComponent } from '../modal/ModalItems'
import Success from '../new-plan/Sucess'
import Failed from '../new-plan/Failed'


const FormDetail = ({handleSubmit, handleUsers, data, open, toggleModal, state}) => {

    console.log('user', data)
  return (
    <Form onSubmit={handleSubmit}>
        <Divider title="General Information" width="44"/>
        <FormItem>
            <NewUserItem>
                <Input type="text" name="email" placeholder="Email Address" onChange={handleUsers} required/>
            </NewUserItem>
            <NewUserItem>
                <Input type="text" name="password" placeholder="Password" onChange={handleUsers} required/>
            </NewUserItem>
            <NewUserItem>
                <select  name="plan" onChange={handleUsers}>
                <option>Plan</option>
                {
                    data?.map(d=> <option key={d._id} value={d._id}>{d.name}</option>)
                }
                </select>
            </NewUserItem>
            <NewUserItem>
                <Input type="text" name="confirmPassword" placeholder="Repeat Password" onChange={handleUsers} required/>
            </NewUserItem>
            <NewUserItem>
                <select name="legalStatus" onChange={handleUsers}>
                <option>Legal Status</option>
                <option>Read</option>
                <option>Modify</option>
                </select>
            </NewUserItem>
            
            <NewUserItem>
            <User>
                <input type="checkbox"/>
                <span>User Active</span>
            </User>
            </NewUserItem>
        </FormItem>
        <Divider title="Contact Details" width="46.5"/>
        <FormItem>
            <NewUserItem>
                <Input type="text" name="lastName"placeholder="Surname" onChange={handleUsers} required/>
            </NewUserItem>
            <NewUserItem>
                <Input type="text" name ="firstName" placeholder="FirstName" onChange={handleUsers} required/>
            </NewUserItem>
            <NewUserItem>
                <Input type="text" name="otherName" placeholder="OtherName" onChange={handleUsers} required/>
            </NewUserItem>
            <NewUserItem>
                <Input type="text" name="phone" placeholder="Phone" onChange={handleUsers} required/>
            </NewUserItem>
            <NewUserItem>
                <Input type="text" name="company" placeholder="Company" onChange={handleUsers} required/>
            </NewUserItem>
            <NewUserItem>
                <select name="country" onChange={handleUsers}>
                <option>Nigeria</option>
                <option>Ghana</option>
                <option>UK</option>
                </select>
            </NewUserItem>
            <NewUserItem>
                <Input type="text" name="address" placeholder="Address" onChange={handleUsers} required/>
            </NewUserItem>
        </FormItem>
        <Button onClick={handleSubmit}>Create user</Button>
        <Toaster
            position='top-center'
            reverseOrder={false}
        />
            <ModalComponent open={open} toggleModal={toggleModal}>

            {
            state === "failed" 
            ? <Failed desc="Failed to create plan, Make sure all inputs are filled" toggleModal={toggleModal}/> 
            : <Success desc="User has been created Successfully"/>           
            }
            
        </ModalComponent>
    </Form>
  )
}

export default FormDetail