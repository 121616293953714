import {useState, useEffect, useCallback} from 'react'
import { useNavigate } from 'react-router-dom';
import { client, TokenHeader } from '../../features/api/axiosInstance';
import { omit } from '../../features/libs/omit';


const usePlanForm = (plan, toggleModel) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [state, setState] = useState("");
    const [msg, setMsg] = useState("")
    let navigate = useNavigate()
  

    const checkEmpty = async ()=>{
        if(typeof plan !== "object"){
           return false;
        }

        let check = Object.values(plan).every(val => val !== "" || val?.length !== 0);
        console.log('validate', check)
        return check
    }

   /**
    * If the form is not empty, then set the state of isSubmitting to true.
    */
    const handleSubmit = async (e)=>{
      e.preventDefault();
      let notEmpty = await checkEmpty();
      console.log(notEmpty);
      if(notEmpty){  
        setIsSubmitting(true);
      }else{
        setIsSubmitting(false);
        setState("failed")
        toggleModel();
      }
      
    }
  
    const sendData = useCallback(async ()=>{
      let data = await omit(["Incoming SMS", "Outgoing SMS"], plan)
      console.log('newplan', data)
      try{
      
        const request = client(TokenHeader);
        console.log("inside request")
        const res = await request.post(process.env.REACT_APP_ENTERPRISE_URL + 'plans', data)
        console.log(res.data)
        console.log("data", res.data.message)
      
        if(res.data !== null) {
          console.log(res.data);
          setMsg(res.data.message)
          setState("success")
          toggleModel()
          setTimeout(()=>{
            navigate(-1);
          }, 3000)
        }
      }catch(err){
        console.log(err);
        setState("failed");
        toggleModel()
      }
    }, [plan, toggleModel, navigate])
  
    console.log("plan-data", plan)
    useEffect(()=>{
      if(isSubmitting){
        setIsSubmitting(false)
        setTimeout(()=>{
        console.log("Need to submit")
         sendData();
        }, 2000)
       
      }
        
    },[isSubmitting, sendData]);

   
    return {handleSubmit, isSubmitting, state, msg};
}

export default usePlanForm