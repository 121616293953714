import React from 'react'
import { PropTypes } from "prop-types";
import Table from './tableTag'
import TableHeader from './TableHeader'
import UserTableBody from './UserTableBody'


const TableTemplate = ({posts, loading, data, myComponent, noInput}) => {
  const MyComponent = myComponent
  
  console.log("post", posts)
  
  return (
    <>
      {
        loading ? <div>Loading....</div> : 
        <Table noInput={noInput}>
          <TableHeader data={data} noInput={noInput}/>
          <Table.Body>
            {posts?.map((item, index)=>(<MyComponent items={item} key={index}/>))}
          </Table.Body>
        </Table>
      }
    </>
  )
}

TableTemplate.propTypes={
  posts: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  noInput: PropTypes.bool
};

export default TableTemplate