import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: ${({noInput})=> noInput? "75.8vw":"72vw"};
  padding: ${({noInput})=> noInput && "10px"};
  /* position: absolute; */
  
`;

export const THead = styled.thead`
  background: rgba(247, 249, 252, 0.8);
  box-shadow: 2px 2px 2px 0 #E5E5E5 2px 2px 2px 0 #E5E5E5;  
`

export const TFoot = styled.tfoot`
  
`;

export const TBody = styled.tbody`
`;

export const TR = styled.tr`
  border-top: 1px solid #E9EDF5;

`;

export const TH = styled.th`
  color: #838191;
  font-size: .72rem;
  display: ${({noInput})=> noInput && "none"};
  div{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${({noInput})=> noInput ? "20px": "6px 10px" };
    background: rgba(247, 249, 252, 0.8);
    backdrop-filter: blur(5px);
  }
`;

export const TD = styled.td`
   background: #fff;
   padding: 5px;
  
`;

