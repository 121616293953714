import { createSlice } from '@reduxjs/toolkit'


export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    value: "close"
  },
  reducers: {
    open: (state, action) => {
        if(state.value === 'close'){
            state.value = action.payload
        }
    },
    
    close: (state, action) => {
      if(state.value === 'open'){
          state.value = action.payload
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { open, close} = sidebarSlice.actions

export default sidebarSlice.reducer