import React from 'react'
import avatar from '../../images/avatar.png'

import {Activity, Comment} from './dropdown.styled'

const DropdownItem = () => {
  return (
    <Activity>
      <img src={avatar} alt="user"/>
      <Comment className="comment">
        <span>raymon made comment on Unix access</span>
        <span>Today at 11.23pm</span>
      </Comment>
      <div className="signal"></div>
    </Activity>
  )
}

export default DropdownItem