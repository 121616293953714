import styled from 'styled-components'

export const LabelContainer = styled.div`
    padding: 10px;
    display: flex;

    span{
        width: 150px;
    }
`
export const Left = styled.div`
    width: 600px;
    height: 32px;
    background: #E5E5E5;
    display: flex;
    padding: 0;
`
export const Calendar = styled.div`
   display: grid;
   place-items: center;
   background: #FFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
   width: 40px;
   height: 100%;
   border: 1px solid #838191;
   border-radius: 5px;
   
   .icon1{
       font-size: 1.5rem;
   }
`
export const Search = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #838191;
    background: #FFF;
    width: 70%;
    select{
        width: 60px;
        height: 80%;
        border: none;
        padding: 5px 0;
    }

    input{
        width: 70%;
        height: 100%;
        border: none;
        border-left: 1px solid #838191;
        /* border-radius: 5px; */
        margin-left: 10px;

        &:focus{
            outline: none;
        }
    }

    input::placeholder{
        padding: 20px;
        font-size: 1.1rem;
    }
`
export const Right = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    width: 500px;
    margin-left: 8rem;
    height: 30px;

`
export const NewUser = styled.div`
    background: #4D9200;
    display: flex;
    justify-content: center;
    position: ${({show})=> show ? "relative": "absolute"};
    right: ${({show})=> show ? " ": "60px"};
    align-items: center;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #4D9200;
    border-radius: 6px;
    padding:8px 12px;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
`
export const File = styled.div`
    background: #E9EDF5;
    position: relative;
    margin-right: 10px;
    border-radius: 6px;
    width: 200px;
    padding: 10px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #E9EDF5;
    display: ${({show})=> show? "block": "none"};
    .inputFile{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    label{
        font-size: 1rem;
        font-weight: 700;
        color: #808080;
        display: flex;
        justify-content:center;
        align-items: center;
        cursor: pointer;
        span{
            display: inline-block;
            margin-left: 5px;
        }
    }

    .icon{
        color: #808080;
        text-align: center !important;
    }
`