import React,{useState, useEffect} from 'react'
import {Container} from '../signup/signup.style'
import FormSignIn from '../../components/form/FormSignIn'
import Form from '../../components/form/Form'
import Banner from '../../components/banner/Banner'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

const SignIn = () => {
  const user = useSelector(state=> state.userReducer);
  const auth = user.isAuth;
  const navigate = useNavigate()

  useEffect(()=>{
    if(auth){
      
      setTimeout(()=>{
        navigate('/dashboard')
      })
    }
   
  }, [auth, navigate])

  return ( 
    <Container>
      <Form>
        <FormSignIn />
      </Form>
      <Banner/>
    </Container>
  )
}

export default SignIn