import {useState} from 'react'
import { processItem } from '../new-plan/planhelpers'

const useDevice = (firstCall, secondCall, thirdCall) => {

    const newDevice = {
        user: "",
        deviceName: "",
        deviceType: "",
        deviceModel: "",
        phone: "",
        imei: "",
        simiccid: ""
    }

    const [device, setDevice] = useState(newDevice);
 

    const getUser = async (val, name)=>{
        let user;
        if(name === "user"){
            user = firstCall?.data.find((d)=> d.contact.company.toLowerCase() === val.toLowerCase());
        }else{
            user = thirdCall?.data.find((d)=> d.deviceModel.toLowerCase() === val.toLowerCase());
        }
        return user._id;
    }

    const handleData = async (e)=>{
        let  user, dType;
        const {name, value} = e.target

        user = name === "user" || name ==="deviceModel" ?  await getUser(value, name): false;
        dType = name === "deviceType" && await processItem(value, secondCall?.data);
        console.log(user, dType)

        switch (name){
            case "user":
                setDevice(prev=>{
                    return {
                        ...prev,
                        user: user
                    }
                })
                break;
            case "deviceType":
                setDevice(prev=>{
                    return {
                        ...prev,
                        deviceType: dType?._id
                    }
                })
                break;
            case "deviceModel":
                setDevice(prev=>{
                    return {
                        ...prev,
                        deviceModel: user
                    }
                })
                break;
            
            default:
                setDevice(prev=>{
                    return {
                        ...prev,
                        [name]: value
                    }
                });
        }
    }

   

    return {handleData, device}
}

export default useDevice