
import React, {useState} from 'react'
import { NewPlanItemContainer, User, Input, NewPlanFormItem } from '../form/dashboardFormContent.styled'
import SelectPage from '../multiSelect/SelectPage'
import { processAdminCost, calculateCheckCost } from './planhelpers'

// const smsItems = ["Incoming SMS","Outgoing SMS"]
// const emailDevice = ["Email", "Maximum Devices"]
const CheckLists = ({
        checkLists, 
        checkItems, 
        setPlan,
        plan
    }) => {
    

    
    const [itemChecked, setItemChecked] = useState([]);

    
    const handleChecked = async (e)=>{
        const {name, value, checked} = e.target
       
        setPlan(prev=>{
            let val =  isNaN(prev[name]) ? 0 : parseInt(prev[name]);
            if(!checked){
                prev.fixedPrice = parseInt(prev.fixedPrice) - val
                prev.totalFee = parseInt(prev.totalFee) - val
                prev[name] = 0
                return {
                    ...prev,
                    checklists: prev?.checklists?.filter(item=> item.id !== value)
                }

            }else{
                return {
                    ...prev
                }
            }
           
        })

        // Set the function to get the
      setItemChecked(prev=>{
        return itemChecked.some(item=> item.title === name) 
            ? itemChecked.filter(item=> item.title !== name) 
            : [...prev, {title: name, id: value}]
      })
    }

    const handleQuantity = async (e)=>{
        const {name, value} = e.target
        const   isPresent = itemChecked?.some(item=> item.title === name)
        if(isPresent){
            const qty = await processAdminCost(value)
            const  {cost, id} = await calculateCheckCost(name, qty, checkItems)                   
           setPlan(prev=>{
            let val =  isNaN(prev[name]) ? 0 : parseInt(prev[name]);
            let newPrev =  prev.checklists.length !== 0 ? prev.checklists.filter(item=> item.id !== id): []
            return {
                ...prev,
                [String(name)]: parseInt(cost),
                fixedPrice: parseInt(prev.fixedPrice) + parseInt(cost) - val,
                totalFee: parseInt(prev.totalFee) + parseInt(cost) - val,
                checklists: [...newPrev, {id: id, quantity: qty}]  
            }
           })
        }
    }
    const handleChange = async (e)=>{
        e.preventDefault();

        const {name, value} = e.target
        if(name === 'administrativeCost'){
            let adminCost = await processAdminCost(value)
            setPlan(prev=> {
                return {
                    ...prev,
                    administrativeCost:  adminCost,
                    totalFee: parseInt(prev.totalFee) + adminCost - parseInt(prev.administrativeCost)
                    
                }
            });
        }else{
            setPlan(prev=> {
                return {
                    ...prev,
                   [name]: value
                }
            })
        }
        
    }
    console.log(itemChecked)
    console.log('checks', checkLists)
    console.log('item', checkItems)
  return (
  
    <NewPlanFormItem>
        <NewPlanItemContainer>
            <Input type="text" placeholder="Plan Name" name="name" onChange={handleChange}/>
        </NewPlanItemContainer>

        <NewPlanItemContainer>
            <SelectPage data={checkLists} setData={setPlan}/>
        </NewPlanItemContainer>

        <NewPlanItemContainer>
            <select name="billingType" onChange={handleChange}>
                <option disabled>Billing Type</option>
                <option value='postpaid'>Postpaid</option>
            </select>
        </NewPlanItemContainer>

        <NewPlanItemContainer>
            <select name="billingCycle" onChange={handleChange}>
                <option disabled>Billing Cycle</option>
                <option value="monthly">Monthly</option>
            </select>
        </NewPlanItemContainer>

        <NewPlanItemContainer>
            <Input 
                type="number" 
                placeholder="Administrative Cost" 
                name="administrativeCost"
                onChange={handleChange}
            />
        </NewPlanItemContainer>

        <NewPlanItemContainer>
            <Input 
                type="text" 
                placeholder="Fixed Price" 
                name="fixedPrice" 
                value={plan?.fixedPrice}
                onChange={handleChange}
                readOnly
            />
        </NewPlanItemContainer>

        <NewPlanItemContainer>
            <select name="currency" onChange={handleChange}>
                <option>Currency</option>
                <option>NGN</option>
            </select>
        </NewPlanItemContainer>

        <NewPlanItemContainer>
            <Input 
                type="text" 
                placeholder="Total Fee" 
                name="totalFee" 
                value={plan?.totalFee}
                onChange={handleChange}
                readOnly
            />
        </NewPlanItemContainer>

        <NewPlanItemContainer>
            <User>
                <input type="checkbox"/>
                <span>Use this as defualt plan</span>
            </User>
        </NewPlanItemContainer>
        <NewPlanItemContainer>
            {/**********Spaces******/}
        </NewPlanItemContainer>
        {/***************Display the SMS header******************* */}
        {
            checkItems?.slice(0, 2).map(({title, _id})=>  
                <NewPlanItemContainer ht={25} key={_id}>
                    <User>
                        <input type="checkbox" name={title} value={_id} onChange={handleChecked}/>
                        <span>{title}</span>
                    </User>
                </NewPlanItemContainer>
            )
        }
       {/***************Display the SMS Inputbox******************* */}
        <NewPlanItemContainer>
            <Input 
                type="text" 
                placeholder="Input Incoming SMS quantity"
                name="Incoming SMS"
                onChange={handleQuantity}
            />
        </NewPlanItemContainer>
        <NewPlanItemContainer>
            <Input 
                type="text" 
                placeholder="Input Outgoing SMS quantity"
                name="Outgoing SMS"
                onChange={handleQuantity}
            />
        </NewPlanItemContainer>
        <NewPlanItemContainer ht={25}>
        {/*************************************************************/}
            <User>
                <input type="checkbox"  name="Email"/>
                <span>Emails</span>
            </User>
        </NewPlanItemContainer>
        {
            checkItems?.slice(2).map(({title, _id})=>  
                <NewPlanItemContainer ht={25} key={_id}>
                    <User>
                        <input type="checkbox" name={title} value={_id}  onChange={handleChecked}/>
                        <span>{title}</span>
                    </User>
                </NewPlanItemContainer>
            )
        }

          {/***************Display the Email and Maximum Device Inputbox******************* */}
       
        <NewPlanItemContainer>
            <Input type="text" placeholder="Email" />
        </NewPlanItemContainer>
        <NewPlanItemContainer>
            <Input type="text" placeholder="Maximum Devices" />
        </NewPlanItemContainer>
        <NewPlanItemContainer ht={25}>
            <User>
                <input type="checkbox"/>
                <span>Store History For</span>
            </User>
        </NewPlanItemContainer>
        
        <NewPlanItemContainer ht={25}></NewPlanItemContainer>

        <NewPlanItemContainer>
            <select >
                <option>Store History For</option>
                <option>Ghana</option>
                <option>UK</option>
            </select>
        </NewPlanItemContainer>
    </NewPlanFormItem>
  
  )
}

export default CheckLists