import styled from 'styled-components/macro'
import {device} from '../styles/responsiveDevices'
export const HeaderStyle = styled.div`
    padding: 0 10px;
    h3{
        font-family:Arial, Helvetica, sans-serif;
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: 5px;
        letter-spacing: 2px;
        padding: 0;
    }
    span{
        display: inline-block;
        color: #838191;
        font-family:Arial, Helvetica, sans-serif;
    }

    @media ${device.tablet}{
      h3{
        font-size: 2rem;
        margin-top: 0;
      }
    }
`
export const HamburgerIcon = styled.div`
  position: absolute;
  top: 4.8rem;
  left: 13.2rem;
  box-shadow: 1px 1px 0px #838191, 1px 1px 0px #838191, 1px 1px 0px #838191;
  display: none;
  cursor: pointer;

  .han-icon{
    font-size: 1.5rem !important;
  }

  @media ${device.tablet}{
    display: ${({nav})=> nav === 'open' ? "none": "grid"};
    place-items: center;
  }
`