import React, {useEffect, useState} from 'react'
import Modal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux';
import { toggleModalItem } from '../../features/redux/modalItem';
import { setModal } from '../../features/redux/modalReducer';
import './modal.css'


/* A function that is used to set the app element. */
Modal.setAppElement('*');


//Display single modal component
export const ModalComponent = ({open, toggleModal, children})=>{
  return (
    
      <Modal
      isOpen={open}
      onRequestClose={toggleModal}
      //appElement={document.getElementById('app')}
      contentLabel="My dialog"
      className="single__modal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
      >
        {children}
      </Modal>
  )
}


//Display Multiple modal components
const ModalItems = ({children}) => {

const dispatch = useDispatch()
let modal = useSelector(state=> state.modalItemSlice)


//Get the modalItems children, find the click item and display.
let titles = children.map((item, index)=> [item.props?.id, index]);
let index = titles.find((item)=> item[0] === modal.title);
console.log("modal",index)
index = index === undefined? 0: index[1];


  function toggleModal() {
    dispatch(toggleModalItem());
  }
  return (
    <>
    {
      index === "undefined" ? null : (
        <Modal
          isOpen={modal.open}
          onRequestClose={toggleModal}
          //appElement={document.getElementById('app')}
          contentLabel="My dialog"
          className="user__modal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          {
            modal.title !== '' && children[index] 
          }
        </Modal>
      )
    }
    </>
   
  )
}

export default ModalItems