import React from 'react'
import Table from './tableTag'

const TableHeader = ({data, noInput}) => {
  return (
    <>
      <Table.Head>
        <Table.TR>
          <Table.TH  noInput={noInput}>
            <div>
              <input type="checkbox" />
            </div>
          </Table.TH>
          {/* <Table.TH>
            <div>
             {noInput? "" : "# ID"}
            </div>
          </Table.TH> */}
          {data.map((item, index)=> <Table.TH key={index}><div>{item.toLocaleUpperCase()}</div></Table.TH>)}
        </Table.TR>
      </Table.Head>
    </>
  )
}

export default TableHeader