import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { client, TokenHeader } from '../../../features/api/axiosInstance';
import { setModalItem, toggleModalItem } from '../../../features/redux/modalItem';
import { DeleteContainer } from '../../form/dashboardFormContent.styled'
import Error from '../../images/error.png' 

const Delete = ({desc, endpoint}) => {
    const dispatch = useDispatch()
    let modal = useSelector(state=> state.modalItemSlice);
    const [deleteItem, setDelete] = useState("")
    console.log(modal.id)

    const handleDelete = ()=>{
        setDelete("confirm")
    }

    const deleteData = useCallback(async ()=>{
        let request = client(TokenHeader)
        try {
            const res = await request.delete(process.env.REACT_APP_ENTERPRISE_URL + `${endpoint}/${modal.id}`)
            if(res.data !== null){
                console.log(res.data)
                setDelete("completed");
                dispatch(toggleModalItem());
                setTimeout(()=>{
                    dispatch(setModalItem("success"));
                    setTimeout(()=>{
                        dispatch(toggleModalItem())
                    }, 1000)
                }, 2000)
            }
        } catch (error) {
            console.log(error)
            dispatch(toggleModalItem());
            setTimeout(()=>{
                dispatch(setModalItem("failed"));
                setTimeout(()=>{
                    dispatch(toggleModalItem())
                }, 1000)
            }, 2000)

        }
    }, [modal.id, dispatch, endpoint])

    useEffect(()=>{
       if(deleteItem === "confirm"){
        setTimeout(()=>{
            deleteData();
        }, 2000)
       }
        
    },[deleteItem, deleteData]);


    return (
        <DeleteContainer>
            <div className='failed__wrapper'>
                <div className='failed__img'>
                    <img src={Error} alt="error" />
                </div>
                <div className='failed__text'>
                 {desc}
                </div>
                <div className="option__button">
                    <button onClick={()=>  dispatch(toggleModalItem())}>Cancel</button>
                    <button onClick={()=> setDelete("confirm")}>Confirm</button>
                </div>
            </div>
        </DeleteContainer>
      )
}

export default Delete