import React from 'react';
import validate from './validateInfo';
import useForm from './useForm';
import{FormContent, FormItem} from './form.styled'


const FormForgetPassword = ({ submitForm }) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );


  return (
    <FormContent pass="forget">
      <form onSubmit={handleSubmit} className='form' noValidate>
        <h1>
          Forget Password
        </h1>
        <FormItem fg="forget">
          {/* <label className='form-label'>Email</label> */}
          <input
            className='form-input'
            type='email'
            name='email'
            placeholder='Enter your email'
            //value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p>{errors.email}</p>}
        </FormItem>
        <button type='submit' className="signup">
          Submit
        </button>
      </form>
    </FormContent>
  );
};

export default FormForgetPassword;
