import styled from 'styled-components'
import {File} from '../../dashboard-queryLabel/label.styled'



/********************************************TAB ITEMS FORM STYLED********************************************/
export const Form = styled.form`
  padding: 20px 10px 0 10px;
  height: ${({enlarge})=> enlarge &&  enlarge +"rem"};
`
export const ItemContainer = styled.div`
  width: 25rem;
  height: 45px;
  margin-bottom: ${({bottom})=> bottom && "2rem"};
  select{
    width: 100%;
    height: 100%;
    background: #F3F4F6;
    border-radius: 5px;
    border: none;
  }
`
export const ItemHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
export const Label = styled.label`
  display: inline-block;
  color: #838191;
  font-size: .875rem;
  margin-right: 5px;
`
export const FormItems = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({logo})=> logo ? " ": "space-between"};
  gap: ${({logo})=> logo? "": "60px 0px"};
  margin: 10px 0;
  padding: 0 10px;
`
export const TextAreaContainer = styled(ItemContainer)`
  margin-bottom: 4.5rem;
`
export const TextArea = styled.textarea`
  background: #F3F4F6;
  border-radius: 8px;
  border: none;

  &::placeholder{
      padding: 10px 20px;
      font-size: 1rem;
  }
  &:focus{
    outline-color: #4D9200;
  }
`
export const ImageLogoContainer = styled(File)`
  width: ${({longWidth})=> longWidth? longWidth : "260"}px;
  height: 45px;
  display: grid;
  place-items: center;
  background: #E9EDF5;
  position: relative;
  margin-right: 20px;
  margin-bottom: 1.5rem;
  border-radius: 6px;
  border: .088rem dotted gray;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: none;
`

export const CheckBoxContainer = styled(ItemContainer)`
  width: ${({setWidth})=> setWidth? setWidth+"rem": "15rem"};
  height: 40px;
  input{
    margin-right: 10px;
    margin-bottom: 0;
  }
`
export const ButtonItems = styled(FormItems)`
  justify-content: flex-end;
  align-items: center;
  margin-top: ${({mt})=> mt ? mt+"rem" : "20px"};
`
export const Button = styled.button`
  /* padding: 0 10px; */
  width: ${({wd})=> wd ? wd + "px" : "100px"};
  height: 35px;
  border: none;
  color: #fff;
  font-size: .85rem;
  font-weight: bold;
  background-color: #${({bgColor})=> bgColor};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #${({bgColor})=> bgColor};
  border-radius: 6px;
  margin-bottom: 1.5rem;
  margin-right: 10px;
  cursor: pointer;
`
/**************************************WALLET STYLED********************************************************/

export const WalletContainer = styled.div`
  padding: 20px 10px 0 10px;
  height: 40rem;
`
export const WalletItems = styled(ItemContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 30px;
  width: 250px;
  height: 200px;
  padding: 15px 20px;
  background: #${({bgColor})=> bgColor && bgColor};
  margin-right: 20px;
`
export const IconBackground= styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid ${({borderR})=> borderR && borderR};
  color: #${({icon})=> icon && icon};
  background: ${({bgColor})=> bgColor && bgColor};
  display: grid;
  place-items: center;
  .icon{
    font-size: 2rem !important;
  }
  
`
export const WalletDesc = styled.span`
  display: inline-block;
  font-size: 1.18rem;
  /* font-weight: 600; */
  color: #505050;
`
export const WalletValue = styled.h2`
  color: #${({wColor})=> wColor && wColor};
  font-size: 2rem;
  padding: 0;
  margin: 0;
`