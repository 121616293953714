import styled from 'styled-components' 

export const Container = styled.div`
  position: absolute;
  top: 4.5rem;
  right: 15px;
  width: 350px;
  height: 400px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  z-index: 10;
`
export const Header = styled.div`
  /* position: absolute;
  top: 20px;
  right: 20px; */
  span{
    display: inline-block;
    font-size: 0.9rem;
    font-weight: bold;
    color: #505050;
    padding: 5px;
    margin-top: 5px;
  }
`
// export const newDrop = styled.div`
//   background: red;
//   //position: absolute;
//   top: 10px;
// `

export const Wrapper = styled.div`
  position: relative;
  max-height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar{
    background: #e5e5e5;;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb{
    background: #c4c4c4;
    border-radius: 0 8px 8px 0;
  }
`
export const Activity = styled.div`
  position: relative;
  padding:20px 20px 0 20px;
  display: flex;
  border-bottom: 1px solid #c4c4c4;
  img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: 10px;
  }

  .signal{
    position: absolute;
    top: 1.3rem;
    left: 8px;
    background: #47abee;
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
`

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 15px 15px;
  span:nth-child(1), span:nth-child(2){
    display: inline-block;
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight:bold;
  }

  span:nth-child(2){
    color: #c4c4c4;
    margin-bottom: 0;
    font-size: .9rem;
  }

`