import {useState, useEffect} from 'react'
import  Sidebar from '../../components/sidebar/Sidebar'
import  Navbar from '../../components/dashboard-nav/Nav'
import {device} from '../../components/styles/responsiveDevices'
import styled from 'styled-components'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux'



const Container = styled.div`
    width: 100vw;
    position: relative;
    height: 100%;
    background: #E5E5E5;
    display:grid;
    grid-template-columns: [col-start] 1fr [col-2] 2fr [col-3] 2fr [col-end];
    grid-template-rows: ${({move})=> move === "new-plan"? "70px minmax(20rem, 86rem)": "70px minmax(20rem, 68rem)"};
    grid-template-areas:
    "h h h"
    "s m m"
    ;
    grid-row-gap: 1px;

    @media ${device.tablet}{
     grid-template-areas: ${({check})=> check === "close"? ` 
     'h h h' 'm m m ' ` 
     : ` 'h h h' 's m m' `
     };
     overflow: hidden;
    }
`


const Dashboard = ()=>{
    const dash = useLocation().pathname.split('/')[2];
    const value = useSelector(state=> state.sidebar.value)
  
    const user = useSelector(state=> state.userReducer);
    const auth = user.isAuth;
    const navigate = useNavigate()
  
    useEffect(()=>{
      !auth && navigate('/login')
    }, [auth, navigate])
    return (
        <Container move={dash} check={value}>
            <Navbar />
            <Sidebar/>
            <Outlet/>
        </Container>
    )
}

export default Dashboard