import React, { 
    useState, 
    createContext, 
    useContext, 
} from "react";
import { useEffect } from "react";


/* Creating a context with a type of `GeofenceCtxs | undefined` and setting the default value to
`undefined` */
const SelectCtx = createContext(undefined)


export const SelectProvider = ({children})=>{
    const [selected, setSelected] = useState([]);

    const toggleOption = async ({ title }) => {
        setSelected(prevSelected => {
            // if it's in, remove
            const newArray = [...prevSelected]
            if (newArray.includes(title)) {
                return newArray.filter(item => item.toLowerCase() !== title.toLowerCase())
                // else, add
            } else {
                newArray.push(title)
                return newArray;
            }
        })
    }

    return (
        <SelectCtx.Provider value={{selected, toggleOption}}>
            {children}
        </SelectCtx.Provider>
    );
}



export const useSelectOption = ()=>{
    const {selected, toggleOption} = useContext(SelectCtx)

    return {selected, toggleOption}
}

