import styled from 'styled-components/macro'
import { FailedContainer, Form } from '../form/dashboardFormContent.styled'

export const ExportContainer = styled(FailedContainer)`
    width: 98%;
    height: 98%;
    display: block;
`

export const ExportForm = styled(Form)`
    padding-top: 3rem;
    position: relative;
    .form__item_input{
        display: flex;
        align-items: center;
        width: 26rem;
        height: 45px;
        margin-top: 10px;
        margin-left: 25px;

    }

    .form__item_input.checkbox{
        flex-wrap: wrap;
        gap: 15px;
        height: 20px;
    }

    .form__item_input select{
        width: 100%;
        height: 100%;
        background: #F3F4F6;
        border-radius: 5px;
        border: none;
    }

    .checkbox-item{
        display: flex;
        align-items: center;
        gap: 8px;
        width: 8rem;
    }

    .checkbox-item span{
        display: inline-flex;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        font-size: .8rem;
        color: #838191;
    }

    .btn__items{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        position: absolute;
        top: 23rem;
        left: 20rem;
    }

    .btn__items .btn_type{
        padding: 10px 20px;
        border-radius: 8px;
        border: none;
        font-size: .8rem;
        font-weight: 400;
        display: grid;
        place-items: center;
    }

    .btn_type.white{
        background-color: #fff;
        border: 1px solid #B5B5B5;
    }

    .btn_type.green{
        background-color: #4D9200;
        color: #fff;
    }
`