import React,{useState} from 'react'
import Form from '../../components/form/Form'
import Banner from '../../components/banner/Banner'
import FormSignup from '../../components/form/FormSignup'
import { Container } from './signup.style'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

// const StyleForm = styled.div`
//   margin-left: 20px;
// `

const SignUp = () => {
 const user = useSelector(state=> state.userReducer);
 const auth = user.isAuth;

 console.log(auth)
  return (

    <>
      {
        auth 
        ? <Navigate to='dashboard' replace/> 
        : (
          <Container>
            <Form>
              <FormSignup/>
            </Form>
            <Banner/>
          </Container>
        )
      }
    </>
      
  )
}

export default SignUp