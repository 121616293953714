import React from 'react'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Gentro from '../images/Logo/gentroLogo.png'
import {Container, Logo, Search, Notification} from './nav.styled'
import Dropdown from '../dropdown/nav-dropdown/Dropdown';
import { toggleMachine } from '../../features/machines/toggleMachine';

import { useMachine } from '@xstate/react';


const Navbar = ()=>{
    const [state, send] = useMachine(toggleMachine);
    return (
        <>
        <Container>
           <Logo>
              <img src={Gentro} alt="gentro logo"/>
           </Logo>
           <Search>
            <input type="text" placeholder="search"/>
           </Search>
           <Notification onClick={() => send('TOGGLE')}>
                <NotificationsNoneOutlinedIcon className="icon"/>
           </Notification>
           {
               state.value === "inactive"? " ": <Dropdown/>
           }
        </Container>
        </>
    )
}

export default Navbar