import React, {useState,useRef, useEffect} from 'react'
import Table from './tableTag'
import Popup from '../dropdown/nested-dropdown/Popup'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { toggleMachine } from '../../features/machines/toggleMachine';
import {useMachine} from '@xstate/react'
import styled from 'styled-components'

const DataItem = styled.div`
  display:flex;
  justify-content: ${({icon})=> icon ? " ": "flex-start"};
  align-items: center;
  padding: 6px;
  width: ${({icon})=> icon ? "50px": " "};
  color: #${({username})=> username};
  font-size: 1.05rem;
  
  & > input {
    margin-right: 20px;
    border-radius: 6px;
    border: 0px solid #838191;
    box-shadow: 2px 2px 2px 0px #fff, 2px 2px 2px 0px #fff;
  }
`
const More = styled(DataItem)`
  display: flex;
  justify-content: center;
  align-items:center;
  position: relative;
  width: 5rem;
  height: 2rem;
  border: 1.75px solid #CFF4D2;
  border-radius: 20px;
  padding: 5px;
  color: #4D9200;
`
const Status = styled(DataItem)`
    padding: 5px 0;
    border-radius: 6px;
    font-size: .95rem;
    color: #${({status})=> status === "Active"? "14B053": "EC1C24"};
    background-color: ${({status})=> status === "Active"? "#CFF4D2": "rgba(255, 79, 81,.21)"};
    &::before{
      content: "";
      height: 8px;
      width: 8px;
      background: #${({status})=> status === "Active"? "14B053": "EC1C24"};
      border-radius: 3px;
      margin: 0 5px;
    }
    /* color: # */
`



const TableBodyItem = ({items}) => {
  const [state, send] = useMachine(toggleMachine)
  const modalRef = useRef(null);
  //Display table pop-up
  const toggle = ()=>{
    if(modalRef.current){
      send("TOGGLE")
    }
  }

  return (
   <>
      <Table.TR>
        <Table.TD>
          <DataItem icon="icon">
            <input type="checkbox"/>
            <ExpandCircleDownOutlinedIcon className="userIcon"/>
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem>
          {items.id}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem username="4D9200">
           {items.type}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem>
           {items.imei}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem>
           {items.name}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <Status status={items.status}>
            {items.status}
          </Status>
        </Table.TD>
        <Table.TD>
          <More ref={modalRef} onClick={toggle}>
          { state.value === "inactive"? <MoreHorizIcon />:<Popup/>}
          </More>
        </Table.TD>
      </Table.TR>
   </>
  )
}

export default TableBodyItem