import React from 'react'
import Table from './tableTag'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

import styled from 'styled-components'

const DataItem = styled.div`
  display:flex;
  justify-content: ${({icon})=> icon ? " ": "flex-start"};
  align-items: center;
  padding: 6px;
  width: ${({icon})=> icon ? "50px": " "};
  color: #${({username})=> username};
  font-size: 1.05rem;
  
  & > input {
    margin-right: 20px;
    border-radius: 6px;
    border: 0px solid #838191;
    box-shadow: 2px 2px 2px 0px #fff, 2px 2px 2px 0px #fff;
  }
`


const TableBodyItem = ({items}) => {
  
  return (
   <>
      <Table.TR>
        <Table.TD>
          <DataItem icon="icon">
            <input type="checkbox"/>
            <ExpandCircleDownOutlinedIcon className="userIcon"/>
          </DataItem>
        </Table.TD>
        {/* <Table.TD>
          <DataItem>
          {items._id}
          </DataItem>
        </Table.TD> */}
        <Table.TD>
          <DataItem username="4D9200">
           {items.name}
          </DataItem>
        </Table.TD>
        <Table.TD>
            <DataItem>
                {items.totalFee}
            </DataItem>
         </Table.TD>
        <Table.TD>
         <DataItem>
            {items.deviceType.length}
         </DataItem>
        </Table.TD>
        {/* <Table.TD>
          <DataItem>
         {items.group}
          </DataItem>
        </Table.TD> */}
        <Table.TD>
          <DataItem>
            {items.billingCycle}
          </DataItem>
        </Table.TD>
      </Table.TR>
   </>
  )
}

export default TableBodyItem