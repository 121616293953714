import React from 'react'
import { FormItemAdjust, User, MapCheckBox } from '../form/dashboardFormContent.styled'
import { calculateCost, processItem } from './planhelpers'

const AvailableMaps = ({mapItems, setPlan}) => {

const handleChecked = async (e)=>{
     // e.preventDefault();
   let  tCost, item;
   const {name, checked} = e.target
   console.log(name, checked)
   if(name){
       tCost = await calculateCost(name, checked, mapItems);
       item = await processItem(name, mapItems);
       setPlan(prev=>{
           return {
               ...prev,
               fixedPrice: parseInt(prev.fixedPrice) + tCost,
               totalFee: parseInt(prev.totalFee) + tCost,
               maps: checked 
                   ? [...prev.maps, {id: item._id, quantity: item.maxRequest}] 
                   : prev.maps?.filter(({id})=> item._id !== id)
           }
       })
   }
}
  return (
  
    <FormItemAdjust>
        {
            mapItems?.map(({title, _id})=> 
                <MapCheckBox key={_id}>
                    <User>
                        <input type="checkbox" name={title} onChange={handleChecked}/>
                        <span>{title}</span>
                    </User>
                </MapCheckBox>
            )
        }
        
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>Google Terrain</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>MapTiler Satellite</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>MapTiler Street Basic</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>MapTiler Street</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>Bing Maps</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>Bing Normal</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>Bing Hybrid</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>Bing Satellite</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>MapBox Normal</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>MapBox Satellite</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>OpenMapsTiles OSM</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>OpenRailway Infrastructure</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span> OpenRailway Signaling</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>OpenRailway Max Speeds</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>OpenRailway Electrification</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>OpenStreet Map</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>Here Satellite</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>Here Normal</span>
            </User>
        </MapCheckBox>
        <MapCheckBox>
            <User>
                <input type="checkbox"/>
                <span>Here Hybrid</span>
            </User>
        </MapCheckBox>
    </FormItemAdjust>    
  )
}

export default AvailableMaps