import React from 'react'
import { SuccessContainer } from '../form/dashboardFormContent.styled'
import Saved from '../images/saved1.png'
const Success = ({desc}) => {
  return (
    <SuccessContainer success>
        <div className='failed__wrapper'>
            <div className='failed__img'>
                <img src={Saved} alt="error" />
            </div>
            <div className='failed__text'>
                {desc}
            </div>
        </div>
    </SuccessContainer>
    
  )
}

export default Success