import React from 'react'
import Header from '../dashboard-header/Header'
import DashboardForm from '../form/DashboardForm'
import Divider from '../divider/Divider'
import {FiUser} from 'react-icons/fi'
import {
    Container, 
    Form, 
    EditFormItem, 
    EditItemContainer, 
    User, 
    Input, 
    Button,
    EditUserBtn
  } from '../form/dashboardFormContent.styled'

const EditUser = () => {
  return (
    <Container>
        <Header title="User"/>
        <DashboardForm heading="Edit User" hg="48rem">
            <Form>
                <Divider title="General Information" width="44" pl="1"/>
                <EditFormItem>
                    <EditItemContainer>
                        {/* <span className='edit__title'>Email</span> */}
                        <Input type="text" placeholder="akinlady12@gmail.com"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>Phone Number</span> */}
                        <Input type="password" placeholder="Password"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>Balance</span> */}
                        <Input type="text" placeholder="N3000"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>Devices</span> */}
                        <Input type="password" placeholder="New Password"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>SubUsers</span> */}
                        <Input type="text" placeholder="Premium"/>
                    </EditItemContainer>
                    <EditItemContainer user={true}>
                        <User>
                            <input type="checkbox"/>
                            <span>User agent</span>
                        </User>
                    </EditItemContainer>
                </EditFormItem>

                <Divider title="Contact Details" width="44" pl="1"/>
                <EditFormItem>
                    <EditItemContainer>
                        {/* <span className='edit__title'>Email</span> */}
                        <Input type="text" placeholder="akinlady12@gmail.com"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>Phone Number</span> */}
                        <Input type="text" placeholder="Password"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>Balance</span> */}
                        <Input type="text" placeholder="N3000"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>Devices</span> */}
                        <Input type="text" placeholder="+2347078345612"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>SubUsers</span> */}
                        <Input type="text" placeholder="Password"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>SubUsers</span> */}
                        <Input type="text" placeholder="Country"/>
                    </EditItemContainer>
                    <EditItemContainer>
                        {/* <span className='edit__title'>SubUsers</span> */}
                        <Input type="text" placeholder="AishaPlaza Place"/>
                    </EditItemContainer>
                </EditFormItem>
                <EditUserBtn>
                    <FiUser/>
                    Save
                </EditUserBtn>
            </Form>
        </DashboardForm>
    </Container>
  )
}

export default EditUser