import styled from 'styled-components'

export const DataItem = styled.div`
  display:flex;
  justify-content: ${({icon})=> icon ? " ": "flex-start"};
  align-items: center;
  padding: 6px;
  width: ${({icon})=> icon ? "50px": " "};
  color: #${({username})=> username};
  font-size: 0.795rem;
  
  & > input {
    margin-right: 20px;
    border-radius: 6px;
    border: 0px solid #838191;
    box-shadow: 2px 2px 2px 0px #fff, 2px 2px 2px 0px #fff;
  }

`
export const More = styled(DataItem)`
  display: flex;
  justify-content: center;
  align-items:center;
  position: relative;
  width: 4rem;
  height: 2rem;
  border: 1.75px solid #CFF4D2;
  border-radius: 20px;
  padding: 5px;
  color: #4D9200;
`

export const Status = styled(DataItem)`
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color:  #4D9200;
  border-radius: 50%;
  color: #fff;
  /* font-weight: 600; */
`