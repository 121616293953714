import {
    addUserStart, 
    addUserSuccess, 
    addUserFailure, 
    loginStart, 
    loginSuccess, 
    loginFailure,
    logOutStart,
    logOutSuccess,
    logOutFailure
} from '../redux/userSlice.js'

import {publicRequest, userRequest} from './apiConfig'

/**
 * It takes a user object and a dispatch function as arguments, and then dispatches an action to the
 * reducer, which then updates the state.
 * @param user - {
 * @param dispatch - this is the dispatch function that is passed to the action creator by the thunk
 * middleware.
 */
export const addUser = async (user, dispatch) =>{
    dispatch(addUserStart());
    try{
        const res = await publicRequest.post("/sign-up",user)
        console.log('inside api', res.data)
        dispatch(addUserSuccess(res.data));
    }catch(err){
        console.log('err')
        if(err) dispatch(addUserFailure());
    }
}

/**
 * It's a function that takes in a result and a dispatch function, and then dispatches a loginStart
 * action, and then makes a post request to the server, and then dispatches a loginSuccess action with
 * the response data, and if there's an error, it dispatches a loginFailure action.
 * @param result - {email: 'email', password: 'password'}
 * @param dispatch - The dispatch function is used to dispatch actions to the store.
 */
export const loginUser = async (result, dispatch) =>{

    dispatch(loginStart());
    console.log('login...')
    try{
        const res = await publicRequest.post('/sign-in', result)
        console.log(res.data)
        dispatch(loginSuccess(res.data));
    }catch(err){
        dispatch(loginFailure());
    }
}

export const logOutUser = async (dispatch)=>{
    dispatch(logOutStart())
    try{
        const res = await userRequest('/sign-out')
        console.log(res.data)
        if(res.data !== null)  dispatch(logOutSuccess(res.data));
       
    }catch(err){
        dispatch(logOutFailure())
    }
}