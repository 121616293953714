import React from 'react'
import DehazeIcon from '@mui/icons-material/Dehaze';
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';
import {open} from '../../features/redux/sidebarSlice'
import { HeaderStyle, HamburgerIcon } from './header.styled';


const Header = ({title}) => {
  const value = useSelector(state=> state.sidebar.value)
  const dispatch = useDispatch()

  return (
    <HeaderStyle>
      <HamburgerIcon onClick={()=> dispatch(open('open'))} nav={value}>
        <DehazeIcon className="han-icon"/>
      </HamburgerIcon>
       <h3>{title}</h3>
       <span>Gentrol Enterprise</span>
    </HeaderStyle>
  )
}

export default Header