import Table from './tableTag'
import styled from 'styled-components'

const DataItem = styled.div`
  display:flex;
  justify-content: ${({icon})=> icon ? " ": "flex-start"};
  align-items: center;
  padding: 6px;
  width: ${({icon})=> icon ? "50px": " "};
  color: #${({username})=> username};
  font-size: 1.05rem;
  
  & > input {
    margin-right: 20px;
    border-radius: 6px;
    border: 0px solid #838191;
    box-shadow: 2px 2px 2px 0px #fff, 2px 2px 2px 0px #fff;
  }
`

const Status = styled(DataItem)`
  padding: 5px 0;
  border-radius: 6px;
  font-size: .95rem;
  width: ${({sub})=> sub && "80px"};
  color: #${({status})=> status === "Active"? "14B053": "EC1C24"};
  background-color: ${({status})=> status === "Active"? "#CFF4D2": "rgba(255, 79, 81,.21)"};
  &::before{
    content: "";
    height: 8px;
    width: 8px;
    background: #${({status})=> status === "Active"? "14B053": "EC1C24"};
    border-radius: 3px;
    margin: 0 5px;
  }
    /* color: # */
`



const TableBodyItem = ({items}) => {

  return (
   <>
      <Table.TR>
        <Table.TD>
          <DataItem>
          {items.id}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem username="4D9200">
           {items.user}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem>
            {`N${items.usage}`}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem>
            {`N${items.balance}`}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <Status status={items.status} sub="sub">
            {items.status}
          </Status>
        </Table.TD>
      </Table.TR>
   </>
  )
}

export default TableBodyItem