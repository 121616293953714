import {useState, useEffect, useCallback} from 'react'
import {useNavigate } from 'react-router-dom'
//import { Token, userRequest } from '../../features/api/apiConfig'
import { client, TokenHeader} from '../../features/api/axiosInstance'
import { notifyError, notifyLoading, notifySuccess } from '../../features/libs/useNotification'

const useUserForm = (endpoint, users, setUsers) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [open, setOpen] = useState(false)
    const [state, setState] = useState("")
    const navigate = useNavigate();

    
  
    const toggleModal = useCallback(()=>{
      setOpen(!open)
    }, [open]);


    const handleUsers = (e)=>{
      e.preventDefault();
      const {name, value} = e.target
      setUsers({
        ...users,
        [name]: value
      })
    }
  
   
    const checkEmpty = async ()=>{
        if(typeof users !== "object"){
           return false;
        }

        let check = Object.values(users).every(val => val !== "");
        console.log(check)
        return check !== undefined && check;
    }

   /**
    * If the form is not empty, then set the state of isSubmitting to true.
    */
    const handleSubmit = async (e)=>{
      e.preventDefault();
      let notEmpty = await checkEmpty();
      console.log(notEmpty);
      if(notEmpty){  
        setIsSubmitting(true);
      }else{
        setIsSubmitting(false);
        setState("failed")
        toggleModal();
      }
      
    }
  
    const sendData = useCallback(async ()=>{
      try{
        //const res = await userRequest.post(`/${endpoint}`, users, config);
        const request = client(TokenHeader);
        console.log("inside request")
        const res = await request.post(process.env.REACT_APP_ENTERPRISE_URL + 'users', users)
        console.log(res.data)
        if(res.data !== null){
          console.log(res.data);
          //notifySuccess("User has been successfully created");
          setState("success")
          toggleModal();
          setTimeout(()=>{
            navigate(-1)
          }, 4000)
          
        }
         
      }catch(err){
        console.log(err);
        setState("failed")
        toggleModal();
        //notifyError("Error sending  data")
      }
    }, [users, toggleModal, navigate])
  
    console.log("user", users)
    useEffect(()=>{
      if(isSubmitting){
        setIsSubmitting(false)
        notifyLoading('Sending data...')
        setTimeout(()=>{
        console.log("Need to submit")
         sendData();
        }, 2000)
       
      }
        
    },[isSubmitting, sendData]);

   
    return {handleSubmit, handleUsers, isSubmitting, state, open, toggleModal};
}

export default useUserForm;