import {useEffect, useState, useCallback} from 'react'
import { useSelector } from 'react-redux';
import { client, TokenHeader } from '../api/axiosInstance';


//Use the client hooks to get the initial data from the server
//Position the url according to the return data from the client
// Usage: const {firstCall} = useSingleClient(endpoint)
const useSingleClient = (endpoint) => {

  const [item, setItem] = useState([]);
  const [err, setErr] = useState('')
  const [data, setData] = useState('loading')
  let modal = useSelector(state=> state.modalItemSlice);

  const handleDelete = useCallback(async (arr) =>{
    let newData;
    if(modal.title === "success" && modal.id !== ""){
      newData = arr?.data.filter(item=> item._id !== modal.id)
      console.log("newdata", newData)
      setItem(newData);
    }else {
      console.log("single", newData)
      setItem(arr);
    }
  }, [modal.id, modal.title])
 
   //Get the devices, and plan addons from the server
  useEffect(()=>{
   
    let request = client(TokenHeader)
    const fetchData = async ()=>{
      try{
        const res =  await request.get(process.env.REACT_APP_ENTERPRISE_URL + endpoint)
        await handleDelete(res?.data);
        setData("success")
      }catch(e){
        setErr(e.message);
        setData("failed")
     
      }
     
    }

    fetchData();
  }, [endpoint, handleDelete]);


  return {item, data}

}

export default useSingleClient