import React, { useState } from 'react'

import Header from '../dashboard-header/Header'
import DashboardForm from '../form/DashboardForm'

import { Container } from '../form/dashboardFormContent.styled'

import NewDeviceForm from './NewDeviceForm'


import useMultipleClients from '../../features/libs/useMultipleClients'
import useDevice from './useDevice'
import { ModalComponent } from '../modal/ModalItems'
import Success from '../new-plan/Sucess'
import useFormhelper from '../../features/libs/useFormhelper'
import Failed from '../new-plan/Failed'



const NewDevice = () => {
  const [open, setOpen] = useState(false)
  const toggleModal = ()=>{
    setOpen(!open)
  }
  const {
    firstCall,
    secondCall, 
    thirdCall, 
    data
  } = useMultipleClients('users', 'device-types', 'device-models')

  const {handleData, device} = useDevice(firstCall, secondCall, thirdCall);
  const   {handleSubmit, state} = useFormhelper(device, 'devices', toggleModal)
  
  
  
  console.log('device', device)
   
  return (
    <Container>
     <Header/>
     {
      data === 'loading' && <h4>Loading available data...</h4>
     }
     {
      data === 'failed' && <h4>OOps newtwork issue, Refresh your browser</h4>
     }
     {
      data === "success" && (
        <>
           <DashboardForm heading="New Device" hg="58rem">
              <NewDeviceForm 
                users={firstCall?.data} 
                deviceType={secondCall?.data}
                model={thirdCall?.data}
                handleDevice={handleData}
                handleSubmit={handleSubmit}
              />

              <ModalComponent open={open} toggleModal={toggleModal}>
                {
                 state === "failed" 
                 ? <Failed desc="Device creation failed, Make sure all inputs are filled" toggleModal={toggleModal}/> 
                 : <Success desc="Device has been created successfully"/>
                }
               
              </ModalComponent>
           </DashboardForm>
        </>
      )
     }
    
    </Container>
  )
}

export default NewDevice