import { createContext, useState, useContext} from "react";
import { countries } from "../../components/select/drop-select/data/data";
const DataContext =  createContext()

export const DataProvider = ({children})=>{
    const [data, setData] = useState({});
    
    const getValues =  (values)=>{
        setData(prevData=>({
            ...prevData,
            ...values
        }));  
        
    }
    return (
        <DataContext.Provider value={{data, getValues}}>
            {children}
        </DataContext.Provider>
    )
}

export const useData = ()=> useContext(DataContext);