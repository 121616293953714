import styled from 'styled-components'


export const Container = styled.div`
    padding: 10px;
    width: 60rem;
    height: ${({hg})=> hg};
    /* position: absolute; */
    /* background: #fff; */
`
export const FormHeader = styled.div`
    background: #4D9200;
    padding: 10px;
    span{
        display: inline-block;
        padding: 0 10px;
        color: #FFF;
        font-size: 1.05rem;
    }
`
export const FormBody = styled.div`
    background: #FFFFFF;
    height: 100%;
    position: relative;
`
export const BackArrow = styled.div`
    padding: 10px 15px;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 20px;
    left: 30px;
    cursor: pointer;
`
export const FormContent = styled.div`
    /* width: 100%; */
    padding: 10px;
    position: absolute;
    top: 80px;
    left: 20px;
`

/***************************FORMCONTENT ITEMS STYLED**********************************/

/***************************ACCOUNT MANAGEMENT STYLED**********************************/
//Inherits from previuos styled declear

export const AccountManageContainer = styled(Container)`
    background: #FFF;
    position: relative;
`

export const AccountManageHeader = styled(FormHeader)`
    background: rgba(255, 255, 255, 1);
    display: flex;
    border-radius: 8px 8px 0px 0px;
    padding: 0;
    position: relative;

    * + * {
        margin-left: 5px;
    }

    .tab__icon{
        font-size: 1rem;
    }
`
export const TabItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 100%;

    span{
        display: inline-block;
        font-size: 0.815rem;
        color: #505050;
        font-weight: 400;
        letter-spacing: 0.2px;
    }
`
export const FormBodyExtend = styled(FormBody)`
    top: 0px;
    height: 100%;
`
export const AccountManageTab = styled(TabItem)`
    padding: 0;
    /* width: 500px; */
    .inactive, .active{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 10px 3px;
        margin: 0;
        color: #505050;
        text-decoration: none;
    }
    .active{
        background: #c3c3c3;
        color: #505050 !important;
        box-shadow: 1px 1px 1px #E6E7E8, 1px 1px 1px #E6E7E8;
    }
`
export const Text = styled.span`
    display: inline-block;
    margin: 0;
`
export const LastTabItem = styled(AccountManageTab)`
  .active{
    border-radius: 0 8px 0 0;
  }
`
export const FirstTabItem = styled(AccountManageTab)`
    .active{
    border-radius: 8px 0 0 0;
  }
`
