import React,{useState, useEffect} from 'react'
import Header from '../dashboard-header/Header'
import DashboardForm from '../form/DashboardForm'
import { Container } from '../form/dashboardFormContent.styled'
import { ModalComponent } from '../modal/ModalItems'
import Failed from './Failed'
import Success from './Sucess'
import NewPlanDetails from './NewPlanDetails'

import useClient from '../../features/libs/useClient'
import usePlanForm from './usePlanForm'

let planData = {
  name: "",
  administrativeCost: 0,
  fixedPrice: 0,
  totalFee: 0,
  currency: "",
  planType: "prepaid",
  billingType: "postpaid",
  billingCycle: "monthly",
  features: [],
  maps: [],
  checklists: [],
  deviceType: []
}

const NewPlan = () => {
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState(planData);

  const toggleModal = ()=>{
    setOpen(!open)
  }

  //Use the cleint hooks to get the initial data from the server
  //Position the url according to the return data from the client
  const {firstCall, secondCall, data} = useClient('device-types', 'plans/add-ons');

 
  const {handleSubmit, state, msg} = usePlanForm(plan, toggleModal)
 
  console.log(firstCall, secondCall)

   
  return (
    <Container>
     <Header/>
     {
      data === 'loading' && <h4>Loading available data...</h4>
     }
     {
      data === 'failed' && <h4>OOps newtwork issue, Refresh your browser</h4>
     }

     {
       data === 'success' && (
        <>
            <DashboardForm heading="New Plan" hg="78rem">
                <NewPlanDetails 
                  toggleModal={toggleModal} 
                  deviceType={firstCall?.data}
                  addons={secondCall?.data}
                  handleSubmit={handleSubmit} 
                  setPlan={setPlan}
                  plan={plan}
                />
            </DashboardForm>
            <ModalComponent open={open} toggleModal={toggleModal}>
              {
                state === "failed" 
                ?  <Failed toggleModal={toggleModal} desc="Failed to Create Plan, Check the value entered"/> 
                :  <Success desc={msg}/>
              }
            </ModalComponent>
        </>
            
       )
     }
    

    </Container>
  )
}


export default NewPlan

