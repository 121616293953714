import styled from 'styled-components' 
import {device} from '../styles/responsiveDevices'


export const FormContent = styled.div`
  padding: 0 20px;
  position: relative;
  top: ${({pass})=> pass === "forget"? "150px": "40px"};


  form {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    position: absolute;

    h1{
      font-size: 1.5rem;
      font-weight: 600;
      color: #008000;
      padding: 0 10px;
    }
    .span {
      font-size: .8rem;
      font-weight: 600;
      display: inline-block;
      margin-top: 5px;
      text-align: center;

      > span{
        color: #008000;
      }
    }
  }

  .signup{
    display: block;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    width: 21.8rem;
    margin: 5px 0  0 8px;
    font-size: 1rem;
    background-color: #008000;
    outline: none;
    border: none;
    color: white;
    font-weight: bold;
  }

  .disabled {
    pointer-events: none;
  }

  @media ${device.tablet}{
    padding: 0;
    .signup{
      width: 15.8rem;
    }
  }
`
export const FormItem = styled.div`
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${({fg})=>fg ==="forget" ? "20px": ""};
  width: 23rem;
  input, select{
    display: block;
    padding: 8px;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    border: none;
    background: #E5E5E5;

    &:focus{
      outline-color: #008000;
      border: none;
    }
  }


  label{
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 6px;
    color: gray;
  }

  p{
    font-size: 0.8rem;
    margin-top: 0;
    margin-bottom: 0;
    color: #f00e0e;
  }

  .icon{
    position: absolute;
    right: 1rem;
    bottom: 0.85rem;
    font-size: 1.3rem;
    color: gray;
  }

  .lang{
    width: 90%;
  }

  @media ${device.mobileL}{
      position: relative

  }

  @media ${device.tablet}{
    .singup{
      width: 150px;
      margin-left: 30px;
    }
     input, select{
        width: 250px;
     }

    .icon{
      left: 235px;
    }

    .lang{
      width: 250px;
    }
  }
`
export const InputSpan = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  bottom: 0px;
  cursor: pointer;

  @media ${device.tablet}{
    right: 8rem;
  }
`

export const Box = styled(FormItem)`
  flex-direction: row;
  justify-content: space-between;

  .input-item{
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      color: #000;
      font-size: 0.875rem;
    }
    input{
      height: 0.9rem;
      width: 30px;
    }
  }

  
  span{
    display: inline-block;
    margin-right: 3rem;
    color: #008000;
  }

`

export const SelectStyle = styled(FormItem)`
  background: gray;
`
export const inputSingupStyle = {
  display: "block",
  borderRadius: "5px",
  height: "40px",
  width: "100%",
  border: "none",
  background: "#E5E5E5"
}

