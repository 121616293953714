import React from 'react'
import styled from 'styled-components'
import Label from '../dashboard-queryLabel/Label'
import Header from '../dashboard-header/Header'
import Pagination from '../pagination/Pagination'
import usePagination from '../../features/libs/usePagination'
import TableTemplate from '../table/TableTemplate'
import DeviceTableBody from '../table/DeviceTableBody'
import ModalItems from '../modal/ModalItems'
import Success from '../new-plan/Sucess'
import Failed from '../new-plan/Failed'
import Delete from '../dropdown/nested-dropdown/Delete'
import { useDispatch } from 'react-redux'
import { toggleModalItem } from '../../features/redux/modalItem'
//import DeviceTable from './DeviceTable'


const Container = styled.div`
    /* background: red; */
    padding: 20px;
    grid-area: m;
`
const Wrapper = styled.div`
    padding: 20px;
`
const TableContainer = styled.div`
  padding: 10px;
`

const dataHeader = ["Device Name", "Status", "Last Connected", "More"]

const Devices = () => {

  const dispatch = useDispatch()
  const device = {
    file: "Add New Device"
  }
  const {
    onNext, 
    onPrev, 
    changeRow, 
    currentPage, 
    currentPosts, 
    totalPages,
    loading,
    postPerPage,
    total
  } = usePagination("devices");

  
  return (
    <Container>
        <Wrapper>
          <Header/>
          <Label desc={device} navTo="new-device"/>
          <TableContainer>
           
            <TableTemplate 
              posts={currentPosts} 
              loading={loading} 
              data={dataHeader} 
              myComponent={DeviceTableBody}
            />
      
            <Pagination 
                page={currentPage}  
                totalPages={totalPages} 
                next={onNext} 
                prev={onPrev} 
                loading={loading} 
                changeRow={changeRow}
                row={postPerPage}
                total={total}
            />
              
          </TableContainer>

          <ModalItems>
              {/* <ExportUser id="exportUser"/> */}
              <Success desc="Device was successfully Deleted" id="success"/>
              <Failed  desc="Failed to delete data" toggleModal={()=>dispatch(toggleModalItem())} id="failed"/>
              {/* <div id='exportUser'>Export User</div> 
              <div id='deactivate'>Deactivate</div>  */}
              <Delete id='delete' endpoint="devices" desc="Are you sure want to delete this device"/>
            </ModalItems>
        </Wrapper>
    </Container>
  )
}

export default Devices