import styled from 'styled-components'
export const PopContainer = styled.div`
    background: #FFF;
    color: #000;
    position: absolute;
    top: -5px;
    z-index: 10;
    width: 130px;
    height:${({toggle})=> toggle === "device-management"? "100px": "132px"};
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 179, 0.1), 
                0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
                0px 5px 15px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(152, 161, 179, 0.1), 
                        0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
                        0px 5px 15px rgba(0, 0, 0, 0.08);
    -moz-box-shadow:    0px 0px 0px 1px rgba(152, 161, 179, 0.1), 
                        0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
                        0px 5px 15px rgba(0, 0, 0, 0.08);
`
export const Wrapper = styled.div`
    width: 100%;
    height: ${({toggle})=> toggle === "device-management"? "80%": "100%"};
    padding: 10px 10px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    * + * {
        margin-top: .7rem;
    }
    
`
export const PopItem = styled.span`
    display: ${({toggle})=> toggle === "device-management"? "none": "inline-block"};
    font-size: .875rem;
    font-weight: 500;
    padding: 3px;
    color: ${({colr})=> "#"+colr};
    cursor: pointer;
    &:hover{
        background-color: #CFF4D2;
        border-radius: 4px;
    }
`