import {useState, useEffect, useCallback} from 'react' 
import { useSelector } from 'react-redux';

import { client, TokenHeader } from '../api/axiosInstance';

const usePagination = (url)=>{

  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerpage] = useState(10);
  const [posts, setPost] = useState([]);
  let modal = useSelector(state=> state.modalItemSlice);
  
  let LastIndexOfPost = currentPage * postPerPage;
  let firstIndexOfPost = LastIndexOfPost - postPerPage;
  let currentPosts = posts?.slice(firstIndexOfPost, LastIndexOfPost)
  let totalPages = Math.ceil(posts?.length/postPerPage);

  // totalPages = isNaN(totalPages) ? false: totalPages;
  console.log("total page", totalPages)
  const onNext = ()=>{
    //Check total pages
    if(currentPage === totalPages){
      return totalPages
    }
    setCurrentPage(prev=> prev + 1)
  }

  const onPrev = ()=>{
    if(currentPage <= 1){
      return 1;
    }
    setCurrentPage(prev=> prev - 1)
  }

  const changeRow = (row) =>{
    if(currentPosts?.length < row) return;
    setPostPerpage(row);
  }

  console.log('post', posts)
  const handlePosts = useCallback(async (arr) =>{
    let newData;
    if(modal.title === "success" && modal.id !== ""){
      newData = arr?.data.filter(item=> item._id !== modal.id)
      console.log("newdata", newData)
      setPost(newData);
    }else {
      console.log("single", newData)
      setPost(arr?.data);
    }
  }, [modal.id, modal.title])

  useEffect(()=>{
    const abortController = new AbortController();
    let request = client(TokenHeader);
    const getData = async () => {
      try{
        setLoading(true)
        let res = await request.get(process.env.REACT_APP_ENTERPRISE_URL + url);
        console.log(res.data)
        await handlePosts(res?.data);

        setLoading(false)
      }catch(e){
        console.log(e.message)
        setLoading(false)
      }
      
    }
    getData();

    return ()=>{
      abortController.abort();
    }
  },[url, handlePosts])

  return {
    onNext, 
    onPrev, 
    changeRow, 
    currentPage, 
    currentPosts, 
    loading, 
    totalPages, 
    postPerPage,
    total: posts?.data?.length
  }
}

export default usePagination;