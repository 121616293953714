import React, {useRef, useState} from 'react'
import Table from './tableTag'
import Popup from '../dropdown/nested-dropdown/Popup'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { toggleMachine } from '../../features/machines/toggleMachine';
import {useMachine} from '@xstate/react'
import {DataItem, More, Status} from './tableBody.styled'



const UserTableBody = ({items}) => {
  console.log('users', items)
  //const [state, send] = useMachine(toggleMachine)
  const [show, setShow] = useState(false)
  const modalRef = useRef(null);
  //Display table pop-up
  const toggle = ()=>{
    if(modalRef.current){
      setShow(prev=> !prev)
    }
  }

  const handleMouse = (e)=>{
    e.preventDefault();
   
    setShow(false)
  
  }

  return (
   <>
      <Table.TR>
        <Table.TD>
          <DataItem icon="icon">
            <input type="checkbox"/>
            <ExpandCircleDownOutlinedIcon className="userIcon"/>
          </DataItem>
        </Table.TD>
        {/* <Table.TD>
          <DataItem>
           {items._id?.slice(-7, -1)}
          </DataItem>
        </Table.TD> */}
        <Table.TD>
          <Status status>
           A
          </Status>
        </Table.TD>
        <Table.TD>
          <DataItem>
         {items.email.replace(" ", "")}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem>
          {items.phone.replace(" ","")}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem adjust="true">
          {items.wallet.balance}
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem adjust="true">
          4
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem adjust="true">
         10
          </DataItem>
        </Table.TD>
        <Table.TD>
          <DataItem>
          {
            items?.loginHistory.length === 0 
            ? "No history" : items.loginHistory[-1]
          }
          </DataItem>
        </Table.TD>
        <Table.TD>
          <More ref={modalRef} onClick={toggle} onMouseLeave={handleMouse}>
          { show ? <Popup id={items._id}/>: <MoreHorizIcon />}
          </More>
        </Table.TD>
      </Table.TR>
   </>
  )
}

export default UserTableBody