import { Form, FormItems, ButtonItems, Button } from './tabItems.styled'
import { InputItems, TextAreaItems, ImageLogoItems } from './tabItemsComponents'
import Divider from '../../divider/Divider'


const ImageData = [
  {id:1, title:"Upload Logo", name: "logo" },
  {id:2, title:"Upload User Interface logo", name:"user" },
  {id:3, title:"Upload Documnets and notification logo", name:"document", longWidth:"350"},
  {id:4, title:"Upload Login Background", name:"loginBackground" },
  {id:5, title:"Uplaod Favicon", longWidth:"200", name:"favicon"}
]


const Branding = ({icon}) => {

  return (
    <Form>

    {/*********************************BRAND SETTING SECTION**************************************/}
     <Divider title="Brand Settings" width="48"/>
     <FormItems>
       <InputItems title="Service Title" placeText="Enter Your Service Titile" icon/>
       <InputItems title="Link to Privacy Policy" placeText="Enter Your Privacy Policy" icon/>
       <TextAreaItems title="Footer Text" placeText="Enter Footer Text" icon/>
       <TextAreaItems title="Terms of Use" placeText="Enter Terms of Use" icon/>
      </FormItems>

{/*********************************CONTACT INFO SECTION**************************************/}
      <Divider title="Contact Information for user" width="41.5"/>
      <FormItems>
       <InputItems title="Promo Website URL" placeText="Enter Web URL" icon/>
       <InputItems title="Email" placeText="Enter Email Address" icon/>
       <InputItems title="Phone Number" placeText="Enter Phone Number" icon bottom/>
      </FormItems>

{/*********************************IMAGES AND LOGO UPLOAD SECTION**************************************/}
      <Divider title="Images and Logo" width="46.5"/>
      <FormItems logo="logo">
        {ImageData.map(item=> <ImageLogoItems 
                                key={item.id} 
                                title={item.title} 
                                name={item.name} 
                                longWidth={item.longWidth && item.longWidth}
                              />
                      )}
      </FormItems>
{/*********************************BRAND BUTTON ELEMENTS**************************************/}
      <ButtonItems>
        <Button bgColor="838191">Reset</Button>
        <Button bgColor="4D9200">Save</Button>
      </ButtonItems>
    </Form>
  )
}

export default Branding