import styled from 'styled-components'
//import Pagination from '../../pagination/Pagination'
import usePagination from '../../../features/libs/usePagination'
import TableTemplate from '../../table/TableTemplate'
import ManageSubTableBody from '../../table/ManageSubTableBody'

//import PlanTable from './PlanTable'


const Container = styled.div`
    /* background: red; */
    padding: 20px 0;
`
const TableContainer = styled.div`
  padding: 10px 0;
`
const data = ["Users", "Usage", "Balance", "Status"];
const url='https://jsonplaceholder.typicode.com/users'
const plans = [
  {id: 1, user: "John Latan", usage: 50000, balance: 30000, status: "Active"},
  {id: 2, user: "Spart Collins", usage: 50000, balance: 25000, status: "Inactive"},
  {id: 3, user: "Light Man", usage: 50000, balance: 25000, status: "Active"},
  {id: 4, user: "Cann Beller", usage: 50000, balance: 25000, status: "Active"},
  {id: 5, user: "Crpx Roy", usage: 50000, balance: 25000, status: "Active"},
  {id: 6, user: "Akandee Akinwale", usage: 50000, balance: 25000, status: "Inactive"},
  {id: 7, user: "John Latan", usage: 50000, balance: 30000, status: "Active"},
  {id: 8, user: "Spart Collins", usage: 50000, balance: 25000, status: "Inactive"},
  {id: 9, user: "Light Man", usage: 50000, balance: 25000, status: "Active"},
  {id: 10, user: "Cann Beller", usage: 50000, balance: 25000, status: "Active"},
  {id: 11, user: "Crpx Roy", usage: 50000, balance: 25000, status: "Active"},
  {id: 12, user: "Akandee Akinwale", usage: 50000, balance: 25000, status: "Inactive"}
]
const ManageSubscription = () => {
  const { 
    loading
  } = usePagination(url);

  
  return (
    <Container>
        <TableContainer>
            <TableTemplate posts={plans} loading={loading} data={data} myComponent={ManageSubTableBody} noInput/>
            {/* <Pagination page={currentPage}  total={totalPages} next={onNext} prev={onPrev} loading={loading} changeRow={changeRow}/> */}
        </TableContainer>
    </Container>
  )
}

export default ManageSubscription
