import styled from 'styled-components'
import Header from '../dashboard-header/Header'
import AccountManagemntForm from './AccountManagemntForm'
import {Outlet, Navigate, useLocation} from 'react-router-dom'


const Container = styled.div`
    /* background: red; */
    padding: 0 20px;
    grid-area: m;
`
const Wrapper = styled.div`
  padding: 0 5px 0 0;
   
`
//The oulet display each tab component 
const AccountManagement = () => {
  let item = useLocation().pathname.split("/")[3]
  console.log(item)
  if(item === undefined){
    return <Navigate replace to="/dashboard/account-management/brand"/>;
  }

  return (
    <Container>
        <Wrapper>
         <Header/>
         <AccountManagemntForm>
          <Outlet/>
         </AccountManagemntForm>
        </Wrapper>
    </Container>
  )
}

export default AccountManagement