import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from 'react-router-dom'
import {
    Container, 
    FormHeader, 
    FormContent, 
    FormBody, 
    BackArrow
} from './dasboardForm.styled'


const DashboardForm = ({children, heading, hg}) => {
    const navigate = useNavigate()
  return (
    <Container hg={hg}>
       <FormHeader >
            <span>{heading}</span>
       </FormHeader>
       <FormBody>
           <BackArrow onClick={()=>navigate(-1)}>
            <ArrowBackIcon/>
           </BackArrow>
           <FormContent> 
            {children}
           </FormContent>
       </FormBody>
    </Container>
  )
}


export default DashboardForm