import { Form, FormItems, ButtonItems, Button } from './tabItems.styled'
import Divider from '../../divider/Divider'
import { InputItems } from './tabItemsComponents'
const data = [
    {
        id: 1, 
        title:"New Password", 
        text:"Enter New Password",  
        icon: true,
        bottom:false
    },
    {
        id: 2, 
        title:"Repeat New Password",
        text:"Repeat New Passwo", 
        icon: true,
        bottom:false
    },
    {
        id: 3, 
        title:"Current Password", 
        text:"Enter Current Passwo", 
        icon: true, 
        bottom:true
    }
]
const Settings = () => {
  return (
    <Form enlarge="40">
        <Divider title="Change Password" width="51.8"/>
        <FormItems>
            {data.map(item=> <InputItems key={item.id} title={item.title} placeText={item.text} icon={item.icon} bottom={item.bottom}/>)}
        </FormItems>
        <ButtonItems mt="3">
            <Button bgColor="4D9200">Save</Button>
        </ButtonItems>
    </Form>

  )
}

export default Settings