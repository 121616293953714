import React from 'react'
import { memo } from 'react';
import { useState } from 'react';
import {RiArrowDownSLine} from 'react-icons/ri'
import { MultiSelectContainer } from './multiStyled';
import { useSelectOption } from './useSelectContext';

const MultiSelect = ({ options, setData}) => {
    const [open, setOpen] = useState(false);
    const {selected, toggleOption} = useSelectOption()
    const handleToggleData = ({id, title})=>{
        toggleOption({title: title })
        setData(prev=>{
            return {
                ...prev,
                deviceType: prev.deviceType.includes(id) 
                    ? prev.deviceType.filter(item => item !== id) 
                    : [...prev.deviceType, id]
            }
        })
    }
    console.log(selected)
    return (
        <MultiSelectContainer>
            <div className="c-multi-select-dropdown__selected" onClick={()=>setOpen(!open)}>
                <div className="selected__options">
                    {
                        selected.length !== 0 
                        ? selected?.map(d=> <span key={d}>{d}</span>) 
                        : <span>Select the device type</span>
                    }
                </div>
                <span>
                    <RiArrowDownSLine/>
                </span>
            </div>
            {
                open ? 
                (
                    <ul className="c-multi-select-dropdown__options" onMouseLeave={()=>setOpen(!open)}>
                        {options?.map((option, index )=> {
                            const isSelected = selected.includes(option.title);

                            return (
                                <li className="c-multi-select-dropdown__option" key={index}>
                                    <input 
                                        type="checkbox" 
                                        checked={isSelected} 
                                        className="c-multi-select-dropdown__option-checkbox"   
                                        onClick={() => handleToggleData({id: option._id, title: option.title})} 
                                        readOnly
                                    />
                                    <span>{option.title}</span>
                                </li>
                            )
                        })}
                    </ul>
                )
                : null
            }
            
        </MultiSelectContainer>
    )
}

export default memo(MultiSelect)