import React from 'react'
import { DividerContainer, Info, Error, Line } from './divider.styled'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Divider = ({title, icon, width, size, mt, pl}) =>{
    return (
        <DividerContainer mt={mt}>
            <Info pl={pl}>{title}</Info>
            <Error icon={icon} size={size}>
                <ErrorOutlineIcon className={`err ${size}`}/>
            </Error>
            <Line width={width}>
                <hr/>
            </Line>
        </DividerContainer>
    )
}

export default Divider