import React, {useState, useEffect} from 'react';
import validateLogin from './validateLogin';
import useForm from './useForm';
import{FormContent, FormItem, Box} from './form.styled'
import  VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Select from '../select/custom-select/Select'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import { loginUser } from '../../features/api/apiCalls';
import { Toaster } from 'react-hot-toast';

const FormSignIn = () => {
  
  const [values, setValues] = useState({
    email: '',
    password: ''
  });
  const { handleChange, handleSubmit, errors, isSubmitting } = useForm(
    loginUser,
    setValues,
    values,
    validateLogin
  );

  

  const {i18n, t} = useTranslation(["common"])

  const [showPass, setShowPass] = useState(false)

  const toggle = () =>{
    setShowPass(()=>{
      let val = showPass ? false: true;
      return val;
    })
  }
  const handleMouseDown = (e)=>{
    e.preventDefault()
  }

  const handleLanguage = (value)=>{
    i18n.changeLanguage(value)
  }

  useEffect(()=>{
    if(localStorage.getItem("i18nextLng")?.length > 2){
      i18next.changeLanguage("en")
    }
  }, []);

  return (
    <FormContent>
      <form onSubmit={handleSubmit} className='form' noValidate>
        <h1>
         {t("signin")}
        </h1>
        <FormItem>
          {/* <label className='form-label'>Email</label> */}
          <input
            className='form-input'
            type='email'
            name='email'
            placeholder='Enter your email'
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p>{errors.email}</p>}
        </FormItem>
        <FormItem>
          {/* <label className='form-label'>Password</label> */}
          <input
            type={showPass? 'text': 'password'}
            name='password'
            placeholder='Enter your password'
            value={values.password}
            onChange={handleChange}
          />
          {showPass 
            ? <VisibilityOutlinedIcon className="icon" onClick={toggle} onMouseDown={handleMouseDown}/>
            : <VisibilityOffOutlinedIcon className="icon" onClick={toggle} onMouseDown={handleMouseDown}/>
          }
          {errors.password && <p>{errors.password}</p>}
        </FormItem>
        <FormItem >
         {/* <LanguageSelect/> */}
         <Select handleLang={handleLanguage}/>
        </FormItem>
        <Box>
          <div className="input-item">
            <input
              type='checkbox'
              name='remember_me'
              value={''}
              onChange={handleChange}
            />
            <span>{t("remember")}</span>
          </div>
          <span><Link to="/forgetpassword" className="link">{t("forget password")}</Link></span>
        </Box>

        {
          isSubmitting ? (
            <button 
              type='submit' 
              className="signup disabled" 
              disabled 
              style={{backgroundColor: "#7DE495", pointerEvents: 'none'}}
              >
              ...
            </button>):
            (<button type='submit'  className="signup">
            {t("signin")}
           </button>)
        }
        <span style={{textAlign: "left", margin: "20px 0 0 10px"}}>
         {t("account")}? <span><Link to='/' className="sign-form">{t("signup")}</Link></span>
        </span>
      </form>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
    </FormContent>
  );
};

export default FormSignIn;
