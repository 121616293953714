import { Form, FormItems, ButtonItems, Button } from './tabItems.styled'
import Divider from '../../divider/Divider'
import { InputItems, CheckBoxItems } from './tabItemsComponents'

const Services = ()=>{
    return (
        <Form>
           <Divider title="Services Preferences" width="50"/>
           <FormItems>
            <InputItems title="Domain" placeText="Enter Your Domain" icon/>
            <InputItems title="Payment System URL" placeText="Enter your Payment System URL" icon bottom/>
           </FormItems>
           <Divider title="Maps" width="55.5" icon/>
           <FormItems logo>
               <CheckBoxItems title="Googl RoadMap"/>
               <CheckBoxItems title="Yandex CrowdSourcing"/>
               <CheckBoxItems title="Google Hybrid"/>
               <CheckBoxItems title="OpenStreet Map"/>
               <CheckBoxItems title="OpenStreet Map2"/>
               <CheckBoxItems title="Wikimapia"/>
           </FormItems>
           <Divider title="Map Default Settings" width="48" icon/>
           <FormItems >
            <InputItems title="Default Map" placeText="Enter Your Default Map" icon/>
            <InputItems title="Default Latitude" placeText="Enter Your Default Latitude" icon/>
            <InputItems title="Default Longitude" placeText="Enter Your Default Longitude" icon/>
            <InputItems title="Default Zoom" placeText="Enter Your Default Zoom" icon bottom/>
           </FormItems>
           <ButtonItems>
             <Button bgColor="4D9200" wd="150">Visual Map Editor</Button>
           </ButtonItems>

           <Divider title="Allow Users to Create Account" width="42.6" icon/>
           <FormItems>
            <CheckBoxItems title="OpenStreet Map"/>
           </FormItems>

           <Divider title="Optional Features" width="49.3" icon/>
           <FormItems>
            <CheckBoxItems title="Show a Link to a website with information about the Model" width="31"/>
            <CheckBoxItems title="Do not apply default setting during deviation" width="24"/>
           </FormItems>
           <ButtonItems>
             <Button bgColor="838191">Reset</Button>
             <Button bgColor="4D9200">Save</Button>
           </ButtonItems>

        </Form>
    )
}

export default Services