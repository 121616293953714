import React from 'react'
import styled from 'styled-components'
import Label from '../dashboard-queryLabel/Label'
import Header from '../dashboard-header/Header'
import Pagination from '../pagination/Pagination'
import usePagination from '../../features/libs/usePagination'
import TableTemplate from '../table/TableTemplate'
import PlanTableBody from '../table/PlanTableBody'

//import PlanTable from './PlanTable'


const Container = styled.div`
    /* background: red; */
    padding: 20px;
    grid-area: m;
`
const Wrapper = styled.div`
    padding: 20px;
`
const TableContainer = styled.div`
  padding: 10px;
`
const dataHeader = ["Plan Name", "payment", "Device Type", "Monthly Payment"];

const Devices = () => {
  const device = {
    file: "Add New Plan"
  }
  const {
    onNext, 
    onPrev, 
    changeRow, 
    currentPage, 
    currentPosts, 
    totalPages,
    loading,
    postPerPage,
    total
  } = usePagination("plans");

  
  return (
    <Container>
        <Wrapper>
          <Header/>
          <Label desc={device} navTo="new-plan"/>
          <TableContainer>
            <TableTemplate 
              posts={currentPosts} 
              loading={loading} 
              data={dataHeader} 
              myComponent={PlanTableBody}
            />
      
            <Pagination 
                page={currentPage}  
                totalPages={totalPages} 
                next={onNext} 
                prev={onPrev} 
                loading={loading} 
                changeRow={changeRow}
                row={postPerPage}
                total={total}
            />
          </TableContainer>
        </Wrapper>
    </Container>
  )
}

export default Devices
