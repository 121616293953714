import { Form, FormItems, ButtonItems, Button } from './tabItems.styled'
import Divider from '../../divider/Divider'
import { InputItems, TextAreaItems, ImageLogoItems, CheckBoxItems } from './tabItemsComponents'


const Messaging = () => {
    return (
        <Form>
            <Divider title="Email Messaging" width="52"/>
           <FormItems>
             <CheckBoxItems title="Default" label width="34"/>
             <InputItems title="Time Zone" icon/>
             <InputItems title="Sender Email" icon bottom/>
           </FormItems>
           <ButtonItems>
             <Button bgColor="4D9200">Save</Button>
           </ButtonItems>

           <Divider title="M2M Messages" width="53"/>
           <FormItems>
            <InputItems title="SMS Gateway" placeText="Enter SMS Gateway" icon/>
            <InputItems title="Sender ID" placeText="Enter Sender ID" icon bottom/>
           </FormItems>

           <Divider title="User Notifications" width="52" mt="2"/>
           <FormItems>
            <InputItems title="Sender ID" placeText="Enter SMS Gateway" icon/>
            <InputItems title="Number of Inbound messages" placeText="Enter Sender ID" icon bottom/>
           </FormItems>
           <Divider />
           <FormItems>
               <CheckBoxItems title="Use Gentrol gateway" />
           </FormItems>
           <ButtonItems>
            <Button bgColor="838191">Reset</Button>
            <Button bgColor="4D9200">Save</Button>
           </ButtonItems>

        </Form>
      )
}

export default Messaging