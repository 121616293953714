import  {createSlice}  from "@reduxjs/toolkit";

const value = {
    currentUser: {},
    isFetching: false,
    isAuth: false,
    error: false
}
const userSlice = createSlice({
    name: "userSlice",
    initialState: value,
    reducers: {
        loginStart: (state) => {
            state.isFetching = true;
         },
        loginSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.currentUser = action.payload;
            state.isAuth = true
        },
        loginFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        addUserStart: (state) => {
            state.isFetching = true;
         },
         addUserSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.isAuth = true;
            state.currentUser = action.payload;
        },
        addUserFailure: (state) => {
            state.isFetching = false;
            state.isAuth = false
            state.error = true;
        },
        logOutStart: (state)=>{
            state.isFetching = true;
        },
        logOutSuccess: (state, action)=>{
            state.isFetching = false;
            state.isAuth = false;
            state.error = false;
            state.currentUser = action.payload;
        },
        logOutFailure: (state)=>{
            state.isFetching = false;
            state.isAuth = false
            state.error = true;
            state.currentUser = {}
        }
    }
});

export const { 
    loginStart, 
    loginSuccess, 
    loginFailure,
    addUserStart,
    addUserSuccess,
    addUserFailure,
    logOutStart,
    logOutSuccess,
    logOutFailure
} = userSlice.actions;
export default userSlice.reducer;