import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { DataProvider } from './features/libs/DataContext';
import { Provider } from 'react-redux'
import { store, persistor } from './features/redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import './components/language/i18n'



ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DataProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </DataProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

