import {Option} from './selectStyled'

const ListItem = ({id, label, value, changeData, handleLang})=>{
  return (
    <Option value={localStorage.getItem("i18nextLng")} onClick={()=>{changeData(label); handleLang(value)}}>
          <input 
          type="radio" 
          className="radio"
          id={id}
          value={value}
          name="category"
          />
          <label htmlFor="automobiles" >{label}</label>
    </Option>
  )
}

export default ListItem