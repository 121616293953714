import Divider from '../../divider/Divider'
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import { 
    WalletContainer, 
    FormItems,WalletItems, 
    WalletDesc, 
    WalletValue, 
    IconBackground, 
    ButtonItems, 
    Button 
} from './tabItems.styled'


const Wallet = ()=>{
    return (
        <WalletContainer>
            <Divider title="Wallet" width="55"/>
            <ButtonItems>
                <Button bgColor="4D9200">Fund Wallet</Button>
            </ButtonItems>
            <FormItems logo>
                <WalletItems bgColor="292929">
                    <IconBackground bgColor="#7DE495" borderR="#CFF4D2" icon="106437">
                        <SnippetFolderIcon className="icon"/>
                    </IconBackground>
                    <WalletDesc>
                        WALLET BALANCE
                    </WalletDesc>
                    <WalletValue wColor="FFF">
                        N500,0000.000
                    </WalletValue>
                </WalletItems>
                <WalletItems bgColor="F6F6F6">
                    <IconBackground bgColor="rgba(51, 42, 42, 0.3)" borderR="rgba(51, 42, 42, 0.1)" icon="505050">
                        <SnippetFolderIcon className="icon"/>
                    </IconBackground>
                    <WalletDesc>
                       USAGE
                    </WalletDesc>
                    <WalletValue wColor="838191">
                    N200,2500.000
                    </WalletValue>
                </WalletItems>
            </FormItems>
        </WalletContainer>
    )
}

export default Wallet