import styled from 'styled-components'
import AccountManagemntForm from '../AccountManagemntForm'

const DesignContainer = styled.div`
    padding: 10px;
`
const Design = () => {
  return (
    <DesignContainer>
        coollllingl
    </DesignContainer>
  )
}

export default Design