export const omit = async (keys, obj) => 
  Object.fromEntries(
    Object.entries(obj)
      .filter(([k]) => !keys.includes(k))
)


// const profile = { name: 'Maria', gender: 'Female', age: 30 }

// const result = omit(['name', 'gender'], profile)

// console.log(result)