import React from 'react'
import AirplayOutlinedIcon from '@mui/icons-material/AirplayOutlined';
import Header from '../dashboard-header/Header' 
import {
    Container, 
    Wrapper, 
    CardContainer, 
    CardItem, 
    Card, 
    Icon
} from './home.styled'


const Home = ()=>{
    return (
        <Container>
          <Wrapper>
            <Header/>
             <CardContainer>
                <Card>
                    <CardItem>
                        <Icon bg="#83BBEE">
                          <AirplayOutlinedIcon/>
                        </Icon>
                        <div className="desc">
                            <h4>5000</h4>
                            <span>Total Devices</span>
                        </div>
                    </CardItem>
                </Card>
                <Card>
                    <CardItem>
                        <Icon bg="#FCB694">
                          <AirplayOutlinedIcon />
                        </Icon>
                        <div className="desc">
                            <h4>53000</h4>
                            <span>Total Devices</span>
                        </div>
                    </CardItem>
                </Card>
                <Card>
                    <CardItem>
                        <Icon bg="#D1E4BD">
                          <AirplayOutlinedIcon/>
                        </Icon>
                        <div className="desc">
                            <h4>11000</h4>
                            <span>Total Devices</span>
                        </div>
                    </CardItem>
                </Card>
             </CardContainer>
          </Wrapper>
        </Container>
    )
}

export default Home