import styled from 'styled-components'

export const DividerContainer = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0 0;
    margin-top: ${({mt})=> mt && mt+"rem"};

    //Spacing the children element immediately after the first child parent of the element
    *+*{
        margin-left: 20px;
    }
`
export const Info = styled.span`
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    color: #505050;
    padding-left: ${({pl})=> pl && pl + 'rem'};
`
export const Line = styled.div`
    width: ${({width})=> width && width+"rem"};
    hr{
        width: 100%;
        height: 2px;
        background-color: #E9EDF5;
        border-width: 0;
        /* border: none; */
        border: ${({border})=> border && border};
    }
`
export const Error = styled.div`
    color: #FBC694;
    display: ${({icon})=> icon ? "block": "none"};
    .err{
        font-size:  .875rem !important;
    }

    .size{
        font-size:  .875rem !important;
    }
`
