import styled from 'styled-components'

export const Container = styled.div`
    /* background: red; */
    padding: 10px;
    grid-area: m;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`
export const FormItem = styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`
export const FormItemAdjust = styled(FormItem)`
    justify-content: flex-start;
`
export const ItemContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    width: 350px;
    height: ${({ht})=> ht ? ht : 45}px;
    margin-top: 10px;
    margin-left: ${({adjust})=> adjust && adjust + "px"};
    select{
        width: 100%;
        height: 100%;
        background: #F3F4F6;
        border-radius: 5px;
        border: none;
      }
`
export const NewUserItem = styled(ItemContainer)`
  width: 26.25rem;
`
/* A styled component that is extending the ItemContainer component. */
export const EditItemContainer = styled(ItemContainer)`
  width: 420px;
  padding-right: 10px;
  .edit__title{
    width: 200px;
    display: inline-flex;
    font-size: .875rem;
    font-weight: 600;
    color: #838191;
  }

  /* padding-left: ${({user})=> user && '11rem' }; */
`
export const EditFormItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  padding-left: 20px;
  padding-bottom: 2rem;
 
`
export const NewPlanItemContainer = styled(ItemContainer)`
  width: 25rem;
`

export const CheckBoxItem = styled(ItemContainer)`
`
export const CheckBox = styled(ItemContainer)`
    height: 25px;
`
export const PlanCheckBox = styled(ItemContainer)`
  width: 11rem;
`
export const MapCheckBox = styled(ItemContainer)`
  width: 13rem;
  height: 20px;
`
export const FeaturesCheckBox = styled(ItemContainer)`
  width: 11rem;
  height: 28px;
`
/* A styled component that is extending the ItemContainer component. */
/* A styled component that is extending the ItemContainer component. */
export const User = styled.div`
  width: 100%;
  height:100%;
  display: flex;
  /* justify-content:flex-start; */
  align-items: center;
  span{
    display: inline-block;
    color: #838191;
    font-size: .85rem;
    font-weight: 500;
    margin-left: 5px;
  }
`
/* Creating a styled component called Input. */
export const Input = styled.input`
  width: 100%;
  height: 100%;
  background: #F3F4F6;
  border-radius: 5px;
  border: none;

  &::placeholder{
      padding: 20px;
      font-size: .9rem;
  }
  &:focus{
    outline-color: #4D9200;
  }
`

export const Button = styled.button`
  position: absolute;
  top: 35rem;
  right: 2.5rem;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: #FFF;
  background: #4D9200;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #4D9200;
  cursor: pointer;
`
export const EditUserBtn = styled(Button)`
  top: 38rem;
  right: 2rem;
  display:flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
`
export const PlanBtn = styled(Button)`
    top: 66.5rem;
`
export const ManageDeviceBtn = styled(Button)`
    top: 16rem;
`
export const DeviceBtn = styled(Button)`
    top: 28rem;
`


export const NewPlanFormItem = styled(FormItem)`
  padding-right: 40px;
`
export const CheckListSelect = styled.div`
  display: flex;
  justify-content: space-between;
`
export const FailedContainer = styled.div`
  width: 98%;
  height: 95%;
  background: #FFFFFF;
  border: 2px dashed #EBEFF2;
  border-radius: 8px;
  display: grid;
  place-items: center;
  .failed__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .failed__img{
    width: 150px;
    height: 150px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .failed__text{
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 20px;
    /* or 100% */
    letter-spacing: 0.2px;
    /* Primary Green */
    color: ${({success})=> success ? "#4D9200" : "#505050"};
    span{
      color: red;
      cursor: pointer;
    }
  }

`
export const SuccessContainer = styled(FailedContainer)`
  /* .failed__text{
    color: 
  } */
`

export const DeleteContainer = styled(FailedContainer)`
  .option__button{
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-top: 1rem;
  }

  .option__button button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 30px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
  }

  .option__button button:nth-child(1){
    background: #fff;
    border: 1px solid #B5B5B5;
    color: #505050;
  }
  .option__button button:nth-child(2){
    background: #E13535;
    border: 1px solid #E13535;
    color: #fff;
  }
`