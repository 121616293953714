import axios from 'axios';
import  __ from 'lodash';


const storage = JSON.parse(localStorage.getItem('persist:root'));
const user = storage !== null && JSON.parse(storage.userReducer).currentUser;
//console.log(user.body.token)
export const TokenHeader =  user.body?.token; 

//console.log(TokenHeader)


export const client =  (token = null) => {
    console.log(token)
    const defaultOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : '',
        },
    };

    return {
        get: async (url, options = {}) => await axios.get(url, {...defaultOptions, ...options}),
        post: async (url, data, options = {}) => await axios.post(url, data, { ...defaultOptions, ...options }),
        put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
        delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
    };
};