import styled from 'styled-components'
import search from '../images/search1.png'

export const Container = styled.div`
    background: #FFF;
    padding: 20px;
    grid-area: h;
    display: flex;
    justify-content: space-between;
    position: relative;
`
export const Logo = styled.div`
   
    padding: 0 20px;
    img{
    width: 100px;
    height: 30px;
    object-fit: cover;
    margin-bottom: 30px;
  }
`
export const Search = styled.div `
   width: 80%;
   position: relative;
   input{
      width: 100%;
      height: 2rem;
      border: none;
      border-radius: 5px;

      &:focus{
          outline: none;
      }

      &::placeholder{
        padding: 20px;
        font-size: 1.1rem;
        color: #4D9200;
      }
   }

   &::after{
    content: "";
    background-image: url(${search});
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 80%;
    width: 50px;
    top: 3px;
    right: 5px;
    transition: all 0.4s;
  }
`
export const Notification = styled.div`
   border-radius: 50%;
   background: #E5E5E5;
   padding: 3px;
   height: 30px;
   width: 30px;
   position:relative;
   text-align: center;
   cursor: pointer;
   .icon{
    font-size: 1.5rem !important;
    text-align: center;
   }

   &::after{
    content: "";
    background-color:red;
    border-radius: 50%;
    position: absolute;
    height: 10px;
    width: 10px;
    right: 5px;
    top: 0;
   }

`