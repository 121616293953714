import { configureStore, combineReducers } from "@reduxjs/toolkit";
import sidebarSlice from './sidebarSlice'
import userSlice from './userSlice'
import modalSliceReducer  from "./modalReducer";
import modalItemSlice from './modalItem'

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'

import storage from 'redux-persist/lib/storage'


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['state']
}
  
/* Combining all the reducers into one reducer. */
const rootReducer = combineReducers({
  userReducer: userSlice,  
  sidebar: sidebarSlice,
  modalReducer: modalSliceReducer,
  modalItemSlice: modalItemSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

/* Creating a store with the persisted reducer and ignoring the actions that are not serializable. */
export const store =  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export let persistor = persistStore(store);
