import React from 'react'
import DropdownItem from './DropdownItem'
import {Container, Wrapper, Header} from './dropdown.styled'


const Dropdown = ({toggle}) => {
  return (
    <Container>
      <Wrapper>
        <Header>
         <span>Notifications</span>
        </Header>
        <DropdownItem/>
        <DropdownItem/>
        <DropdownItem/> 
        <DropdownItem/> 
        <DropdownItem/>
        <DropdownItem/>
      </Wrapper>
    </Container>
  )
}

export default Dropdown