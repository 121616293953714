import React from 'react'

import { FormItemAdjust, User,  FeaturesCheckBox } from '../form/dashboardFormContent.styled'
import { calculateCost, processItem } from './planhelpers'

const Features = ({features, setPlan}) => {
  
    const handleChecked = async (e)=>{
         // e.preventDefault();
       let  tCost, item;
       const {name, checked} = e.target
       console.log(name, checked)
       if(name){
           tCost = await calculateCost(name, checked, features);
           item = await processItem(name, features);
           setPlan(prev=>{
               return {
                   ...prev,
                   fixedPrice: parseInt(prev.fixedPrice) + tCost,
                   totalFee: parseInt(prev.totalFee) + tCost,
                   features: checked 
                       ? [...prev.features, {id: item._id, quantity: item.maxRequest}] 
                       : prev.features?.filter(({id})=> item._id !== id)
               }
           })
       }
    }
  return (
    
    <FormItemAdjust>
        {
            features?.map(({title, _id})=> 
                <FeaturesCheckBox key={_id}>
                    <User>
                        <input type="checkbox" name={title} value={title} onChange={handleChecked}/>
                        <span>{title}</span>
                    </User>
                </FeaturesCheckBox>
            )
        }
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Task</span>
        </User>
        </FeaturesCheckBox>
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Schedule Reports</span>
        </User>
        </FeaturesCheckBox>
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Events Notification</span>
        </User>
        </FeaturesCheckBox>
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Gecoding</span>
        </User>
        </FeaturesCheckBox>
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Location by Cell Id</span>
        </User>
        </FeaturesCheckBox>
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Map Layers</span>
        </User>
        </FeaturesCheckBox>
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Routing</span>
        </User>
        </FeaturesCheckBox>
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Driver Journals</span>
        </User>
        </FeaturesCheckBox>
        <FeaturesCheckBox>
        <User>
            <input type="checkbox"/>
            <span>Fleets</span>
        </User>
        </FeaturesCheckBox>
    </FormItemAdjust>
  )
}

export default Features