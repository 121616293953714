import React from 'react'
import Divider from '../divider/Divider'
import { Dispatch } from 'react'
import { Form } from '../form/dashboardFormContent.styled'
import { ExportContainer, ExportForm } from './userModal.styled'
import { useDispatch } from 'react-redux'
import { setModalItem, toggleModalItem } from '../../features/redux/modalItem'

const data = [
    {
        id: 1,
        text: "Email Address"
    },
    {
        id: 2,
        text: "Sub-User"
    },
    {
        id: 3,
        text: "Balance"
    },
    {
        id: 4,
        text: "Last Login"
    },
    {
        id: 5,
        text: "Status"
    },
    {
        id: 6,
        text: "Devices"
    },
] 

const ExportUser = () => {
    const dispatch = useDispatch()
    const handleClick = (val)=>{
        //e.preventDefault();
        dispatch(setModalItem(val))
        // dispatch(toggleModalItem())
    }

    const handleCancel = (e)=>{
        e.preventDefault();
        dispatch(toggleModalItem());
    }
  return (
    <ExportContainer>
        <ExportForm>
        {/**************************************INPUT ITEMS**************************************/}
            <Divider title='Export Device' width='35' border='1px solid #E9EDF5'/>
            <div className="form__item_input">
                <select>
                    <option value="pdf">Select File Format</option>
                    <option value="pdf">PDF</option>
                    <option value="csv">CSV</option>
                </select>
            </div>
       {/**************************************CHECKBOX ITEMS***************************************/}
            <Divider title='Attributes to Export' width='33' mt="1.5" border='1px solid #E9EDF5'/>
            <div className="form__item_input">
              <div className="checkbox-item">
                <input type='checkbox' />
                <span>Select All</span>
              </div>
            </div>
            <div className="form__item_input checkbox">
                {
                    data.map((item)=>{
                        return(
                            <div className="checkbox-item" key={item.id}>
                                <input type='checkbox' name={item.text} value={item.text}/>
                                <span>{item.text}</span>
                            </div>
                        )
                    })
                }
            </div>
{/**************************************BUTTONs ITEMS***************************************/}
            <div className="btn__items">
                <button className='btn_type white' onClick={handleCancel}>Cancel</button>
                <button className='btn_type green' onClick={()=>handleClick('success')}>Export</button>
            </div>
        </ExportForm>
    </ExportContainer>
  )
}

export default ExportUser