import React, {useState, useRef, useEffect} from 'react'
import ListItem from './OptionItem'
import {data} from './data'
import {
  Container, 
  Selected, 
  SelectBox,
  OptionContainer,
  SearchBox
} from './selectStyled'

const Select = ({handleLang, noSearch})=>{
  const [active, setActive] = useState("default")
  const [item, setItem] = useState('Select your choice')
  const [query, setQuery] = useState("")
  const [dataItem, setDataItem] = useState(data)
  const myRef = useRef()
 
  
  //Handle input change
  const handleChange = (e)=>{
    e.preventDefault()
    setQuery(e.target.value)
    filterData(e.target.value)
  }
  const filterData = (qry)=>{
    let fitData = data.filter((item)=> item["id"].includes(qry.toLowerCase()))
    if(fitData.length > 0){
      setDataItem(fitData)
    }else {
      setDataItem(data)
    }
  }
  //toggle select
  const handleToggle = ()=>{
    if(active === "default"){
      setActive("active")
    }else {
      setActive("default")
    }
  }

  

  useEffect(()=>{
    const changeValue = ()=>{
      myRef.current.innerHTML = item
    }
    if(item !== 'Select your choice' ){
      changeValue()
    }
  }, [item])

  //reset the data options
  const changeData = (val)=>{
    if(val.length > 0){
      setItem(val)
      setActive("default")
      setQuery(" ")
      setDataItem(data)
    }
  }

 
  return (
    <Container>
    <Selected ref={myRef} className="selected" onClick={handleToggle}>
      {item}
    </Selected>
     <SelectBox active={active}>
       <OptionContainer  className={active}>
         {
           dataItem.map((item, index)=>(
           <ListItem key={index} id={item.id} label={item.label} value={item.value} changeData={changeData} handleLang={handleLang}/>))
          }
       </OptionContainer>
      <SearchBox className="search-box" noSearch={noSearch}>
        <span>Search Languaage</span>
        <input type="text" placeholder="serach here..." value={query} className="input-search" onChange={(e)=> handleChange(e)}/>
      </SearchBox>
     </SelectBox>
    </Container>
  )
}

export default Select