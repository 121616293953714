import React from 'react'
import styled from 'styled-components'
import Label from '../dashboard-queryLabel/Label'
import Header from '../dashboard-header/Header'
import Pagination from '../pagination/Pagination'
import usePagination from '../../features/libs/usePagination'
import TableTemplate from '../table/TableTemplate'
import DeviceManagementTableBody from '../table/DeviceManageTableBody'
//import DeviceManagementTable from './DeviceManagementTable'


const Container = styled.div`
    /* background: red; */
    padding: 20px;
    grid-area: m;
`
const Wrapper = styled.div`
    padding: 20px;
`
const TableContainer = styled.div`
  padding: 10px;
`
const data = ["Device Type","Imei" , "Device Name", "Status", "More"]
const url='https://jsonplaceholder.typicode.com/users'
const mposts = [
  {id: 1, type: "mobile", imei: "345681O9H7", name: "Dell", status: "Active"}, 
  {id: 2, type: "mobile", imei: "345681O9H7", name: "Lobby Bot", status: "Inactive"},
  {id: 4, type: "mobile", imei: "345681O9H7", name: "Dell", status: "Active"}, 
  {id: 5, type: "mobile", imei: "345681O9H7", name: "Lobby Bot", status: "Inactive"},
  {id: 6, type: "mobile", imei: "345681O9H7", name: "Dell", status: "Active"}, 
  {id: 7, type: "mobile", imei: "345681O9H7", name: "Lobby Bot", status: "Inactive"}
]
const DeviceManagement = () => {
  const device = {
    file: "Add New Device",
    user: "Upload Device"
  }
  const {
    onNext, 
    onPrev, 
    changeRow, 
    currentPage, 
    currentPosts, 
    totalPages, loading
  } = usePagination(url);

  
  return (
    <Container>
        <Wrapper>
          <Header/>
          <Label desc={device} show navTo="new-deviceManage"/>
          <TableContainer>
            {/* <DeviceManagementTable posts={currentPosts} loading={loading} data={data}/> */}
            <TableTemplate posts={mposts} loading={loading} data={data} myComponent={DeviceManagementTableBody}/>
            <Pagination page={currentPage}  total={totalPages} next={onNext} prev={onPrev} loading={loading} changeRow={changeRow}/>
          </TableContainer>
        </Wrapper>
    </Container>
  )
}

export default DeviceManagement