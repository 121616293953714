import { createSlice } from "@reduxjs/toolkit";

const value = {
    title: '',
    isOpen: true,
}

export const modalSliceReducer = createSlice({
    name: 'modalReducer',
    initialState: value,
    reducers:{
        setItem: (state, action) => {
            state.title = action.payload;
        },

        setModal:(state)=>{
            if(state.isOpen === true){
                state.isOpen = false
            }
        },
    }
});

export const {setItem, setModal} = modalSliceReducer.actions;

export default modalSliceReducer.reducer;