export const processItem = async (name, checkItems)=>{
    return  checkItems.find(({title})=> title.toLowerCase() === name.toLowerCase()); 
}

export const processAdminCost = async (cost)=>{
    return isNaN(parseInt(cost)) ? 0 : parseInt(cost);
}

export const calculateCost = async (name, checked, addonItems)=>{
    let item = addonItems.find(({title})=> title.toLowerCase() === name.toLowerCase())
   return checked 
    ? parseInt(item.unitPrice * item.maxRequest) 
    : parseInt(-1 * item.unitPrice * item.maxRequest);
}

export const calculateCheckCost = async (name, qty, obj)=>{
    let item = obj.find(({title})=> title.toLowerCase() === name.toLowerCase())
    let cost = parseInt(item.unitPrice * qty)
    return {cost, id: item._id};
}

