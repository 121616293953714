import React from 'react'
import styled from 'styled-components'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {PropTypes} from 'prop-types'
const PaginationContainer = styled.div`
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  /* position: relative;
  top: 11rem;
  width: 73%; */
`
const Right = styled.div`
  padding: 0 10px;
`
const Left = styled.div`
  padding: 0 10px;
  display: flex;
`
const RowSelect = styled.div`
  margin-right: 10px;
  select{
    background: #E5E5E5;
    border: none;
  }
`

const Next = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 1px solid #838191; 
  color: #838191;
  .arrow{
    font-size: .75rem ;
  }
`
const Prev = styled(Next)`
`
const Pages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Desc = styled.span`
  display: inline-block;
  font-size: .8rem;
  font-weight: 550;
  color: #838191;
`
const PageNum = styled(Desc)`
  font-size: .75rem;
  color: #050502;
  padding: 0 5px;
`
const Arrow = styled(Desc)`
  font-weight: bold;
  padding: 10px;
`

const Pagination = ({page, totalPages, next, prev, loading, changeRow, row, total}) => {
  console.log("to", total)
  return (
    <>
      {
        loading 
        ? null: 
        <PaginationContainer>
          <Right>
            <Desc> 1-{row}{"  "}of{"  "}{isNaN(total) ? 0 : total}</Desc>
          </Right>
          <Left>
              <RowSelect>
                <Desc>Rows per page:</Desc>
                <select onChange={(e)=>changeRow(e.target.value)}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
              </RowSelect>
              <Pages>
                <Prev onClick={()=>prev()}>
                  <Arrow>
                    <ArrowBackIosNewIcon className="arrow"/>
                  </Arrow>
                </Prev>
                <PageNum>{page}/{totalPages}</PageNum>
                <Next onClick={()=>next()}>
                  <Arrow>
                    <ArrowForwardIosIcon className="arrow"/>
                  </Arrow>
                </Next>
              </Pages>
            </Left>
        </PaginationContainer>
      }
    </>
    
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  next: PropTypes.func,
  prev: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  changeRow: PropTypes.func
}
export default Pagination;