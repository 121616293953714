import Header from '../dashboard-header/Header'
import DashboardForm from '../form/DashboardForm'
import Divider from '../divider/Divider'

import {
  Container, 
  Form, 
  FormItem,  
  User, 
  Input, 
  Button,
  NewUserItem
} from '../form/dashboardFormContent.styled'

import useUserForm from './useUserForm'
import { useState } from 'react'

import useSingleClient from '../../features/libs/useSingleClient'
import FormDetail from './FormDetail'


const NewUser = () => {
  const [users, setUsers] = useState({
    email : "",
    plan: "",
    legalStatus:"",
    password:"",
    confirmPassword:"",
    firstName:"",
    lastName: "",
    company:"",
    address : "",
    country:"",
    phone: ""

  })
  
  const {handleUsers, handleSubmit, state, open, toggleModal} = useUserForm("users", users, setUsers);
  const {item, data} = useSingleClient("plans");

 console.log('item', item)

  return (
    <Container>
     <Header title="New User"/>
     <DashboardForm heading="New User" hg="55.5rem">
      {
        data === "loading" && <div>Loading users ...</div>
      }
       {
        data === "failed" && <div>Oops failed to load data, refresh your browser ...</div>
      }
      {
        data === "success" && (
          <FormDetail 
            handleSubmit={handleSubmit} 
            handleUsers={handleUsers} 
            state={state}   
            data={item?.data}
            open={open} 
            toggleModal={toggleModal}
          />
        )
      }
       
     </DashboardForm>
    </Container>
  )
}

export default NewUser