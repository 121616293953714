import { useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Error} from '../../divider/divider.styled'
import {Input} from '../../form/dashboardFormContent.styled'
import {DropDown} from '../../select/drop-select/DropDown'
import {
    ItemContainer, 
    ItemHeader, 
    Label,
    ImageLogoContainer, 
    TextArea,
    TextAreaContainer,
    CheckBoxContainer
} from './tabItems.styled'

/*******************************************ALL TABS COMPONENTS***********************************************/

//FORM LABEL HEADER
export const LabelHeader = ({title, icon, size})=>(
    <ItemHeader>
        <Label>{title}</Label>
        <Error icon={icon} size={size}>
            <ErrorOutlineIcon className='err'/>
        </Error>
    </ItemHeader>
)

//FORM INPUT COMPONENTS
export const InputItems = ({title, icon, placeText, bottom})=>{
    return (
        <ItemContainer bottom={bottom}>
            <LabelHeader title={title} icon={icon} size="size"/>
            <Input type="text" placeholder={placeText}/>
        </ItemContainer>
    )
}

//DROPDOWN SELECT
export const SelectItems = ({
    title, 
    icon, 
    bottom,
    id, 
    data, 
    placeText,
    label
})=>{
    const [value, setValue] = useState(null);
    return (
        <ItemContainer bottom={bottom}>
            <LabelHeader title={title} icon={icon}/>
            <DropDown 
            options={data} 
            prompt={placeText} 
            id={id}
            label={label}
            onChange={val=> setValue(val)} 
            value={value}
          /> 
        </ItemContainer>
    )
}

//FORM TEXT-AREA COMPONENTS
export const TextAreaItems = ({title, icon, placeText})=>{
    return (
        <TextAreaContainer>
            <LabelHeader title={title} icon={icon}/>
            <TextArea rows="5" cols="52" placeholder={placeText}/>
        </TextAreaContainer>
    )
}

//FORM IMAGE/LOGO UPLAOD
export const ImageLogoItems = ({title, longWidth, name}) =>{
    const [value, setValue] = useState({})
    const handleChange = (e)=>{
        setValue(e.target.files)
    }
    console.log(value[0])
    return (
        <ImageLogoContainer show="show" longWidth={longWidth}>
            <input type="file" name="file" id={name} className="inputFile" onChange={handleChange}/>
            <Label htmlFor={name}><span>{value[0] ? value[0].name: title}</span></Label>
        </ImageLogoContainer>
    )
}

//FORM CHECKBOXE COMPONENTS
export const CheckBoxItems = ({title, width, label})=>{
    return (
        <>
             {
             label? (
                       <div>
                        <LabelHeader title={title} icon/>
                        <CheckBoxContainer setWidth={width}>
                            <input type="checkbox" />
                            <Label><span>{title}</span></Label>
                        </CheckBoxContainer>
                      </div>
                      )
                      :
                      (
                      <CheckBoxContainer setWidth={width}>
                        <input type="checkbox" />
                        <Label><span>{title}</span></Label>
                      </CheckBoxContainer>
                      )
            }
        </>
    )
}