import {useState, useEffect, useCallback} from 'react'
import { useNavigate } from 'react-router-dom';
import { client, TokenHeader } from '../../features/api/axiosInstance';


const useFormhelper = (obj, endpoint, toggleModal) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [state, setState] = useState("")
    const navigate = useNavigate();

    const checkEmpty = async ()=>{
        if(typeof obj !== "object"){
           return false;
        }

        let check = Object.values(obj).every(val => val !== "")
        console.log('validate', check)
        return check !== undefined && check;
    }

   /**
    * If the form is not empty, then set the state of isSubmitting to true.
    */
    const handleSubmit = async (e)=>{
      e.preventDefault();
      let notEmpty = await checkEmpty();
      console.log(notEmpty);
      if(notEmpty){  
        setIsSubmitting(true);
      }else{
        setIsSubmitting(false);
        setState("failed")
        toggleModal();
      }
      
    }
  
    const sendData = useCallback(async ()=>{
      try{
        //const res = await userRequest.post(`/${endpoint}`, users, config);
        const request = client(TokenHeader);
        console.log("inside request")
        const res = await request.post(process.env.REACT_APP_ENTERPRISE_URL + endpoint, obj)
        console.log(res.data)
        if(res.data !== null) {
          console.log(res.data);
          setState("success")
          toggleModal()
          setTimeout(()=>{
            navigate(-1)
          }, 3000)
        }
      }catch(err){
        console.log(err);
        setState("failed")
        toggleModal()
        //navigate(0);
      }
    }, [ endpoint, obj, toggleModal, navigate])
  
    console.log("data", obj)
    useEffect(()=>{
      if(isSubmitting){
        setIsSubmitting(false)
        setTimeout(()=>{
        console.log("Need to submit")
         sendData();
        }, 2000)
       
      }
        
    },[isSubmitting, sendData]);

   
    return {handleSubmit, isSubmitting, state};
}

export default useFormhelper