import React, {useState} from 'react';
import validate from './validateInfo';
import useForm from './useForm';
import{FormContent, FormItem, inputSingupStyle, InputSpan} from './form.styled'
import  VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
//import Country from '../select/Country'
import {Link} from 'react-router-dom'
import { useData } from '../../features/libs/DataContext';
import { addUser } from '../../features/api/apiCalls';
//import toast, { Toaster } from 'react-hot-toast';

const input1 = [
  {
    id: 1, 
    type: "text", 
    name: "name", 
    placeText: "Enter Your Username"
  },
  {
    id: 2, 
    type: "text", 
    name: "company",
    placeText: "Enter Your Company Name"
  },
  {
    id: 3, 
    type: "text", 
    name: "email",
    placeText: "Enter Your Email"
  },
  {
    id: 4, 
    type: "text", 
    name: "website",
    placeText: "Enter Your Company Website"
  }
]

const input2 = [
  {
    id: 5, 
    name: "password",
    placeText: "Enter your password"
  },
  {
    id: 6, 
    name: "confirmPassword",
    placeText: "Confirm your password"
  }
]
const FormSignup = () => {
  const {data, getValues} = useData()
  const [values, setValues] = useState({
    name: '',
    email: '',
    company:'',
    country: 'nigeria',
    website:'',
    password: '',
    confirmPassword: ''

  });

  const submitForm = (val)=>{
    console.log('after submit',val)
  }
  const { handleChange, handleSubmit, errors, isSubmitting } = useForm(
    addUser,
    setValues,
    values,
    validate
  );

  console.log(isSubmitting)

 

  const [showPass, setShowPass] = useState(false)
  const toggle = () =>{
    setShowPass(()=>{ 
      let val = showPass ? false: true;
      return val;
    })
  }

  const handlePhone=(phone)=>{
    getValues({phone: '+' + phone?.phone});
  }
  const handleMouseDown = (e)=>{
    e.preventDefault();
  }

  return (
    <FormContent>
      <form onSubmit={handleSubmit} className='form' noValidate>
        <h1>
          Sign Up
        </h1>
        {
          input1.map(item=> (
            <FormItem key={item.id}>
              <input
                className='form-input'
                type={item.type}
                name={item.name}
                placeholder={item.placeText}
                value={values[item.name]}
                onChange={handleChange}
             />
              {errors[item.name] && <p>{errors[item.name]}</p>}
            </FormItem>
          ))
        }
        <FormItem>
          <PhoneInput 
            inputProps={{
              name: 'phone',
              required: true
            }}
              containerStyle={{width: "100%"}} 
              inputStyle={{...inputSingupStyle}}
              value={data.phone} 
              onChange={phone=> handlePhone({phone})} 
            />
        </FormItem>
        <FormItem>
              <input
                className='form-input'
                type="text"
                name="country"
                placeholder="Country"
                value={"nigeria"}  
                readOnly
             />
          </FormItem>
        {/* <FormItem>
          <CustomSelect/>
        </FormItem> */}

        {
          input2.map(item=> (
            <FormItem key={item.id}>
              <input
                type={showPass? 'text': 'password'}
                name={item.name}
                placeholder='Enter your password'
                value={values[item.name]}
                onChange={handleChange}
              />
              {
              showPass
              ? <InputSpan><VisibilityOutlinedIcon className="icon" onClick={toggle} onMouseDown={handleMouseDown}/></InputSpan> 
              : <InputSpan><VisibilityOffOutlinedIcon className="icon" onClick={toggle} onMouseDown={handleMouseDown}/></InputSpan>
              }
              {errors[item.name] && <p>{errors[item.name]}</p>}
            </FormItem>
          ))
        }

        {
          isSubmitting ? (
            <button type='submit' className="signup" disabled style={{backgroundColor: "#7DE495"}}>
              Sign up
            </button>):
            (<button type='submit' className="signup">
              Sign up
            </button>)
        }
        
        <span className="span">
          Already have an account?<span><Link to="login" className="sign-form">Sign In</Link></span>
        </span>
      </form>
    </FormContent>
  );
};

export default FormSignup;
