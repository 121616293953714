import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    title: '',
    id:"",
    open: false
}

const modalItemSlice = createSlice({
    name: 'modalItem',
    initialState: initialValue,
    reducers:{
        setModalItem:(state, action)=>{
            
            state.title = action.payload;
          
        },

        toggleModalItem: (state)=>{
            state.open = !state.open;
        },

        setId: (state, action)=>{
            state.id = action.payload
        }
    }
})

export const {setModalItem, toggleModalItem, setId} = modalItemSlice.actions;
export default modalItemSlice.reducer;