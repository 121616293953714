import React from 'react'
import {Container, Image} from './banner.style'
import banner from '../images/gentLogo.png'


const Banner = () =>{
    return (
        <Container>
           <Image>
               <img src={banner} alt="banner"/>
            </Image>
        </Container>
    )
}

export default Banner