import axios from 'axios'
import __ from 'lodash'

// const storage = JSON.parse(localStorage.getItem('persist:root'));
// const user = JSON.parse(JSON.stringify(storage.userReducer)).currentUser;
// export const Token = __.isEmpty(user) ? "Token Not Available" : user.body.token; 

// console.log(Token)


/* Creating a new axios instance with a baseURL of http://165.227.213.93:3700. */
export let publicRequest = axios.create({
    baseURL: process.env.REACT_APP_ENTERPRISE_URL,
})

/* This is creating a new axios instance with a baseURL and headers. */
export let userRequest = axios.create({
    baseURL: process.env.REACT_APP_ENTERPRISE_URL,
    headers:{
        "Content-Type": "application/json",
        "Authorization": `Bearer ${""}`
    }
});





