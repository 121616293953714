import React from 'react'
import Divider from '../divider/Divider'
import { Form, FormItem, ItemContainer, User, CheckBox, PlanBtn } from '../form/dashboardFormContent.styled'
import AvailableMaps from './AvailableMaps'
import CheckLists from './CheckLists'
import Features from './Features'


const NewPlanDetails = ({
        deviceType, 
        addons, 
        toggleModal, 
        handleSubmit,
        setPlan, 
        plan
    }) => {
        
  
   
  return (
    <Form>
    {/******************Add New Plan Form: Plan type with checkboxes*********************/}
        <Divider title="Add New Plan" width="48"/>
        <CheckLists 
            checkLists={deviceType} 
            checkItems={addons?.checklists}
            setPlan={setPlan}
            plan={plan}
        />
    
    {/****************************Available Maps: Choose plan type************************************/}
        <Divider title="Available Maps" width="44" icon/>
        <AvailableMaps mapItems={addons?.maps}   setPlan={setPlan}/>
    
    {/****************************Available Features: Features Section************************************/}
        <Divider title="Available Features" width="43" icon/>
        <Features features={addons?.features}  setPlan={setPlan}/>
    
    {/****************************Plan Group Btn: Button Section************************************/}
        <Divider title="Plan Group" width="47" icon/>
        <FormItem>
            <ItemContainer>
                {/* <select >
                <option>Plan Group</option>
                <option>Read</option>
                <option>Modify</option>
                </select> */}
            </ItemContainer>
            <CheckBox wg="24.4rem">
            <User>
                <input type="checkbox"/>
                <span>Users are allow to select this plan</span>
            </User>
            </CheckBox>
        </FormItem>
        <PlanBtn onClick={handleSubmit}>
            Create New Plan
        </PlanBtn>
        
    </Form>
  )
}

export default NewPlanDetails