import {Container, FormBodyExtend} from '../form/dasboardForm.styled'
import AccountTab from './AccountTab'
const AccountManagemntForm = ({children}) => {
  return (
    <Container>
      <AccountTab/>
        <FormBodyExtend>
          {children}
        </FormBodyExtend>
    </Container>
  )
}

export default AccountManagemntForm