import React, {useState} from 'react'
import MultiSelect from './MultiSelect'
import { SelectProvider } from './useSelectContext';




const SelectPage = ({data, setData}) => {
    
    return (
        <SelectProvider>
             <MultiSelect options={data} setData={setData}/>
        </SelectProvider>
    )
}

export default SelectPage