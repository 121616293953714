import { useEffect, useRef, useState } from 'react'
import './styles.css'

export const DropDown = ({
    options, 
    prompt, 
    value, 
    onChange, 
    id, 
    label
}) => {

    const [open, setOpen] = useState(false)
    const [query, setQuery] = useState("")
    const myRef = useRef()
    useEffect(()=>{
        ["click", "touchend"].forEach((e)=>{
            document.addEventListener(e, toggle)
        });

        return ()=> ["click", "touchend"].forEach((e)=>{
            document.removeEventListener(e, toggle)
        })
    }, [])

    function toggle(e){
        setOpen(e && e.target === myRef.current)
    }

    function filter(options){
        return options.filter(
            option=> option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    }
    function displayValue(){
        if(query.length > 0) return query;
        if(value) return value[label];

        return ""
    }
    function selectOption(option){
        setQuery("")
        onChange(option)
        setOpen(false)
    }
  return (
    <div className='dropdown'>
        <div className="control">
            <div className="selected-value">
                <input 
                    // style={{width: "100%", outlineColor: "#4D9200"}}
                    type="text" 
                    ref={myRef} 
                    placeholder={value ? value[label] : prompt} 
                    value={displayValue()} 
                    id={id}
                    onChange={(e)=>{ setQuery(e.target.value); onChange(null)}}
                    onClick={toggle}
                />
            </div>
            <div className={`arrow ${open ? "open": null}`}/>
        </div>
        <div className={`options ${open ? "open": null}`}>
            {filter(options).map(option=>
                        <div 
                            key={option[id]}
                            className={`option ${value === option? "selected" : null}`} 
                            onClick={()=> selectOption(option)}>{option[label]}</div>
                        )}
        </div>
    </div>
  )
}

