import React, {useState} from 'react'
import styled from 'styled-components'
import Label from '../dashboard-queryLabel/Label'
import Header from '../dashboard-header/Header'
import Pagination from '../pagination/Pagination'
import usePagination from '../../features/libs/usePagination'
import TableTemplate from '../table/TableTemplate'
//import UserTable from './UserTable'
import UserTableBody from '../table/UserTableBody'
import DeleteModal from '../modal/DeleteModal'

import Modal from 'react-modal'
import './style.css'
import ModalItems from '../modal/ModalItems'
import ExportUser from './ExportUser'
import Success from '../new-plan/Sucess'
import Delete from '../dropdown/nested-dropdown/Delete'
import Failed from '../new-plan/Failed'
import { useDispatch } from 'react-redux'
import { toggleModalItem } from '../../features/redux/modalItem'


const Container = styled.div`
    /* background: red; */
    padding: 20px;
    grid-area: m;
`
const Wrapper = styled.div`
    padding: 10px;
`
const TableContainer = styled.div`
  padding: 5px;
`
const dataHeader = ["Status", "Email Address", "Phone Number", "Balance", "Devices","Sub-Users", "Last Login", "More"]
const Users = () => {
 
  const dispatch = useDispatch();
  const desc = {
    file: "Create New User",
    user: "Import User"
  }
  const {
    onNext, 
    onPrev, 
    changeRow, 
    currentPage, 
    currentPosts, 
    totalPages,
    loading,
    postPerPage,
    total
  } = usePagination("users");

  
  return (
    <Container>
        <Wrapper>
          <Header title='User'/>
          <Label desc={desc} show navTo="new-user"/>
          <TableContainer>
            <TableTemplate 
              posts={currentPosts} 
              loading={loading} 
              data={dataHeader} 
              myComponent={UserTableBody}
            />
      
            <Pagination 
                page={currentPage}  
                totalPages={totalPages} 
                next={onNext} 
                prev={onPrev} 
                loading={loading} 
                changeRow={changeRow}
                row={postPerPage}
                total={total}
            />
          </TableContainer>
           
            <ModalItems>
              <ExportUser id="exportUser"/>
              <Success desc="Device was successfully Exported" id="success"/>
              <Failed  desc="Failed to delete data" toggleModal={()=>dispatch(toggleModalItem())} id="failed"/>
              {/* <div id='exportUser'>Export User</div> */}
              {/* <div id='deactivate'>Deactivate</div> */}
              <Delete desc="Are you sure you want to delete this user" id='delete' endpoint=""/>
            </ModalItems>
        </Wrapper>
    </Container>
  )
}

export default Users