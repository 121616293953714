import styled from 'styled-components'
import {device} from '../styles/responsiveDevices'


export const Container = styled.div`
    padding: 20px;
    grid-area: m;
`
export const Wrapper = styled.div`
    padding: 10px;

`
export const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;

    @media ${device.tablet}{
        /* display: flex;
        flex-direction: column; */
        gap: 5px 10px;
    }
`
export const Card =styled.div`
    width: 20rem;
    height: 8rem;
    padding: 10px;
    background: #ffF;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #E5E5E5, 1px 1px 1px #E5E5E5;

    //MObile responsivesness
    @media ${device.laptopL}{
        width: 18rem;
        margin-right: 15px;
        padding: 10px 0;
    }

    @media ${device.laptop}{
        width: 19rem;
       padding: 10 10px 10px 0;
    }

    @media ${device.tablet}{
        /* display: grid;
        place-items: center; */
        width: 19rem;
        padding: 20px;
    }
`

export const Icon = styled.div`
    display: grid;
    place-items: center;
    background: ${({bg})=> bg};
    color: #fff;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;

    @media ${device.laptop}{
        width: 2.3rem;
        height: 2.3rem;
    }

    @media ${device.tablet}{
        width: 2.8rem;
        height: 2.8rem;
    }
`
export const CardItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10px;
    div{
       margin-left: 20px;
    }

    .desc{
        h4{
            font-size: 2.3rem;
            margin-bottom: 0;
            margin-top: 10px;
            color: #505050;
        }

        span{
            display: inline-block;
            font-size: .9rem;
            font-weight: 400;
            color: #838191;
        }
    }

    //Responsivesness
    @media ${device.laptop}{
        justify-content: center;
        align-items: center;
        div{
            display: grid;
            place-items: center;
            margin: 0 5px;
        }

        .desc{
            h4{
                font-size: 1.5rem;
                /* margin: 0; */
            }

            span{
                font-size: .85rem;
            }
        }
       
    }
    @media ${device.tablet}{
        justify-content: center;
        align-items: center;
        gap: 20px 20px;
       
        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
        }

        .desc{
            h4{
                font-size: 2.3rem;
                /* margin: 0; */
            }

            span{
                font-size: .95rem;
            }
        }
       
    }

    
`

