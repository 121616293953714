import React from 'react'
import Header from '../dashboard-header/Header'
import DashboardForm from '../form/DashboardForm'
import Divider from '../divider/Divider'
import Country from '../select/Country'
import {
  Container, 
  Form, 
  FormItem, 
  ItemContainer, 
  Input, 
  ManageDeviceBtn
} from '../form/dashboardFormContent.styled'




const NewDeviceManagement = () => {
  return (
    <Container>
     <Header/>
     <DashboardForm heading="New Device" hg="58rem">
        <Form>
          <Divider title="Add Device" width="53" />
          <FormItem>
              <ItemContainer>
                  <select>
                    <option>Device Type</option>
                    <option>Read</option>
                    <option>Modify</option>
                  </select>
              </ItemContainer>
              <ItemContainer adjust="25">
                  <Input type="text" placeholder="IMEI/ID"/>
              </ItemContainer>
          </FormItem>
            <ManageDeviceBtn>Create New Device</ManageDeviceBtn>
        </Form>
     </DashboardForm>
    </Container>
  )
}

export default NewDeviceManagement