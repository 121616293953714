import styled from 'styled-components/macro'
import  { device } from '../styles/responsiveDevices'
export const Container = styled.div`
    background: #FFF;
    grid-area: s;
    box-shadow: 13px 13px 13px 0px rgba(143, 141, 141, 0.20);
    -webkit-box-shadow:  13px 13px 13px 0px rgba(143, 141, 141, 0.20);
    -moz-box-shadow:  13px 13px 13px 0px rgba(143, 141, 141, 0.20);
    transform-origin: 1px;
    transform: translateX(0);
    transition: all 0.8s linear;
    @media ${device.tablet}{
       display: ${({side})=> side === 'open' ? "block": "none"};
       transform: ${({side})=> side === 'close'? 'translateX(-100%)' : 'translateX(0)'};
       transition: all 0.8s linear;
       transform: ${({ side }) => side === 'open' ? 'translateX(0)' : 'translateX(-100%)'};
    }
`
export const Wrapper = styled.div`
    position: relative;
    padding: 30px;
`
export const Profile = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 10px;
   img{
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
    }

    @media ${device.tablet}{
        position: relative;
        margin-right: 0;
    }

`
export const Close = styled.div`
    position: absolute;
    top: -20px;
    right: -15px;
    cursor: pointer;
    border:none;
    /* border-radius: 50%; */
    background: #f3f3f3;
    color: #838191;
    display: none;
    place-items: center;
    box-shadow: 1px 1px 0px #fff, 1px 1px 0px #fff, 1px 1px 0px #838191;
    .close{
        font-size: 1.3rem !important;
    }

    @media ${device.tablet}{
        display: grid;
        place-items: center;
    }
`
export const Desc = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
    .title{
        display: inline-block;
        font-size: 1.2rem;
        font-weight: 500;
        color: #505050;
    }
    span{
        font-size: .8rem;
        color: #C4C4C4;
    }
`
export const SidebarItems = styled.div`
    margin-top: 30px;
`

export const Item = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 0;
  transition: all .2s ease-in-out;
  span{
      display: inline-block;
      margin-left: 8px;
      color: #838191;
      font-size: 1rem;
    }

  &:hover{
      background: #CFF4D2;
      /* opacity: .5; */
      border-radius: 5px;
      transform: scale(1.1);

    span{
        color: #4D9200;
    }
  }
`
export const LogOut  = styled.div`
    padding: 2.5rem;
    border-top: 1.5px solid #E5E5E5;
    height: ${({move})=> move === "new-plan"? "45rem": "30rem" };
    color: red;
    div {
        display: flex;
        justify-content: start;
        align-items: center;
        cursor: pointer;

        span{
            display: inline-block;
            margin-left: 10px;
            font-size: 1.2rem;
        }
    }
    /* .icon{
        color: red;
        font-size: 30px;
    } */
`
export const Toggle = styled.div`
    padding: 2.5rem;
`