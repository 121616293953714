import React from 'react'
import styled from 'styled-components'
import plan from '../images/icons/plan-30.png'
import folder from '../images/icons/folder30.png'
import ssd from '../images/icons/ssd_30px.png'
import user from '../images/icons/user30.png'
import report from '../images/icons/report-30.png'
import avatar from '../images/avatar.png'
import LogoutIcon from '@mui/icons-material/Logout';
import {Link, useLocation} from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux'
import {close} from '../../features/redux/sidebarSlice'
import { logOutUser } from '../../features/api/apiCalls'
import toast, {Toaster} from 'react-hot-toast';

import { 
    Container, 
    Wrapper, 
    Profile, 
    Close, 
    Desc, 
    SidebarItems, 
    Item, 
    LogOut, 
    Toggle 
} from './sidebar.styled'




const Sidebar = ()=>{
    const location = useLocation().pathname.split('/')[2];
    const value = useSelector(state=> state.sidebar.value)
    const user = useSelector(state=> state.userReducer.currentUser?.body?.data)
    console.log(user)
    const dispatch = useDispatch()
    const notify = ()=> toast.success("LogOut successfully ...")
    const handleLogOut = ()=>{
        notify()
        logOutUser(dispatch)
    }
    return (
        <Container side={value}>
            <Wrapper>
                <Profile>
                    <img src={avatar} alt=""/>
                    <Desc>
                        <span className="title">{user?.name}</span>
                        <span>{user?.company}</span>
                    </Desc>
                    <Close onClick={()=> dispatch(close('close'))}>
                        <CloseIcon className="close"/>
                    </Close>
                </Profile>
                <SidebarItems>
                    <Item>
                        <Link to="/dashboard" className="link">
                            <img src={plan} alt="" />
                            <span>Dashbaord</span>
                        </Link>
                    </Item>
                    <Item>
                        <Link to="user" className="link">
                            <img src={user} alt="" />
                            <span>Users</span>
                        </Link>
                    </Item>
                    <Item>
                       <Link to="device" className="link">
                            <img src={ssd} alt="" />
                            <span>Devices</span>
                       </Link>
                    </Item>
                    <Item>
                       <Link to="plan" className="link">
                            <img src={plan} alt="" />
                            <span>Plans</span>
                       </Link>
                    </Item>
                    <Item>
                        <Link to="device-management" className="link">
                            <img src={plan} alt="" />
                            <span>Device management</span>
                        </Link>
                    </Item>
                    <Item>
                        <Link to="account-management" className="link">
                            <img src={folder} alt="" />
                            <span>Account management</span>
                        </Link>
                    </Item>
                    <Item>
                       <Link to="report" className="link">
                            <img src={report} alt="" />
                            <span>Reports</span>
                       </Link>
                    </Item>
                </SidebarItems>
            </Wrapper>
            <LogOut move={location} >
                <div onClick={handleLogOut}>
                    <LogoutIcon className="icon"/>
                    <span>logout</span>
                </div>
            </LogOut>
            <Toggle>
               Toggle
            </Toggle>
        </Container>
    )
}

export default Sidebar