import { createMachine } from 'xstate';

export const toggleMachine = createMachine({
    id: 'toggle',
    initial: 'inactive',
    states: {
      inactive: {
        on: { TOGGLE: 'active' }
      },
      active: {
        on: { TOGGLE: 'inactive' }
      },
      reset:{
        on:{
          RESET: "inactive"
        }
      }
      
    }
});
  