import React, { useState } from 'react';
import styled from 'styled-components'
import Gentro from '../images/Logo/gentroLogo.png'
import {device} from '../styles/responsiveDevices'

const Container = styled.div`
  flex: 1.6;
`
const Left = styled.div`
  position: absolute;
  top: 15px;
  left: 3rem;
 
  img{
    width: 100px;
    height: 30px;
    object-fit: cover;
    margin-bottom: 30px;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  position: relative;
  padding: 0 1rem;

  @media ${device.mobile}{
    margin: 0 auto;
  }
`

const Form = ({children}) => {
  return (
      <Container>
        <Wrapper>
          <Left>
            <img  src={Gentro} alt='Gentro Logo' />
          </Left>
          {/* <FormSignup submitForm={submitForm} /> */}
          {children}
        </Wrapper>
      </Container>
  );
};

export default Form;
