import styled from 'styled-components'
import arrow from '../../images/down1.png'
import search from '../../images/search1.png'


export const Container = styled.div`
    margin-top: 0;
 
`
export const Selected = styled.div`
  width: 100%;
  padding: 12px 10px;
  background: #E5E5E5;
  border-radius: 8px;
  margin-bottom: 8px;
  color: gray;
  font-size: 14px;
  position: relative;
  order: 0;

  &::after{
    content: "";
    background-image: url(${arrow});
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
    position: absolute;
    height: 50%;
    width: 30px;
    top: 10px;
    right: 5px;
    transition: all 0.4s;
    transform: rotateX('180deg');
  }
`
export const SelectBox = styled.div`
  display: flex;
  width: 90%;
  padding: 12px 24px;
  position: relative;
  flex-direction: column;
  box-shadow: 1px 1px 2px gray, 1px 1px 2px gray;
  border-radius: 8px;
  opacity: ${({active})=> active === "active"? 1 : 0};
  
  .active{
    max-height: 200px;
    opacity: 1;
    overflow-y: scroll;
    margin-top: 60px;
  }

  .active ~ .search-box{
    opacity: 1;
    pointer-events: auto;
  }
  
`
export const OptionContainer = styled.div`
  color: gray;
  width: 100%;
  max-height: 0;
  opacity: 0;
  transition: all 0.4s;
  border-radius: 5px;
  order: 1;
  overflow: hidden;
  &::-webkit-scrollbar{
    background: #e5e5e5;;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb{
    background: #f3f3f3;
    border-radius: 0 8px 8px 0;
  }
`
export const Option = styled.div`
  padding: 12px 30px;
  margin-right: 10px;
  cursor: pointer;
  label{
    cursor: pointer;
    font-size: 0.98rem;
    font-weight: 530;
  }
  &:hover{
    background-color: #B7FFBF;
    border-radius: 5px;

    label{
      color: #008000;
    }
  }

  .radio{
    display: none;
  }
`

export const SearchBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.4s;
  opacity: 0;

  .input-search{
    width: 100%;
    padding: 12px 15px;
    position: absolute;
    top: 25px;
    border-radius: 8px;
    &:focus{
      outline-color: #008000;
    }
  }


  &::after{
    content: "";
    background-image: url(${search});
    background-size: contain;
    background-repeat: no-repeat;
    //border-radius: 50%;
    position: absolute;
    height: 120%;
    width: 30px;
    top: 35px;
    right: 10px;
    z-index: 1;
  }

  > span{
    font-size: 1rem;
    font-weight: 500;
    color: gray;
  }
`