import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    /* background: red; */
    padding: 20px;
    grid-area: m;
`
const Wrapper = styled.div`
    padding: 20px;
`

const Reports = () => {
  return (
    <Container>
        <Wrapper>
            Reports
        </Wrapper>
    </Container>
  )
}

export default Reports