import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import FilterAltSharpIcon from '@mui/icons-material/FilterAltSharp';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from 'react-router-dom'
import { 
    LabelContainer, 
    Left, 
    Right, 
    Calendar, 
    Search, 
    NewUser, 
    File } from './label.styled';




const Label = ({show, desc, navTo}) =>{
    const navigate = useNavigate()
    return (
        <LabelContainer>
         <Left>
            <Calendar>
               <CalendarViewMonthOutlinedIcon className="icon1"/>
            </Calendar>
            <Search>
                <FilterAltSharpIcon/>
                <select >
                    <option value="">All</option>
                </select>
                <input type="text" placeholder="Search"/>
            </Search>
         </Left>
         <Right>
             <File show={show}>
              <input type="file" name="file" id="file" className="inputFile"/>
              <label htmlFor="file"><SaveAltOutlinedIcon className="icon"/><span>{desc.user}</span></label>
             </File>
             <NewUser onClick={()=>navigate(`/dashboard/${navTo}`)} show={show}>
              {navTo === "new-user" ? <FilterAltSharpIcon/>: <AddIcon/> }
              <span>{desc.file}</span>
             </NewUser>
         </Right>
        </LabelContainer>
    )
}

export default Label