import React from 'react'
import { FailedContainer } from '../form/dashboardFormContent.styled'
import Error from '../images/error.png' 

const Failed = ({toggleModal, desc}) => {
  return (
    <FailedContainer>
        <div className='failed__wrapper'>
            <div className='failed__img'>
                <img src={Error} alt="error" />
            </div>
            <div className='failed__text'>
                {desc}, <span onClick={toggleModal}>Try Again</span>?
            </div>
        </div>
    </FailedContainer>
  )
}

export default Failed