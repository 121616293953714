import React,{useState} from 'react'
import {Container} from '../signup/signup.style'
import FormForgetPassword from '../../components/form/FormForgetPassword'
import Form from '../../components/form/Form'
import Banner from '../../components/banner/Banner'

const ForgetPassowrd = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  return (
    <Container>
      <Form>
       <FormForgetPassword submitForm={submitForm}/>
      </Form>
      <Banner/>
    </Container>
  )
}

export default ForgetPassowrd