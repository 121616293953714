import { useState, useEffect} from 'react';
import { useData } from '../../features/libs/DataContext';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

const useForm =(callback, setValues, values, validate) => {
  const {data, getValues} = useData();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const notify = ()=> toast.error("Error submitting data")
  const notifySuccess = ()=> toast.success("Redirecting...")

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    getValues({[name]: value})
  };
  
  const handleSubmit = e => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
    
  };

  useEffect(()=>{
    if(Object.keys(errors).length > 0 && !isSubmitting){
      notify()
    }
  }, [errors, isSubmitting])
 

  useEffect(
    () => {

      if(
          Object.keys(errors).length === 0 && (Object.keys(data).length >= 7 || 
          Object.keys(data).length ===2) && isSubmitting
        ){
          notifySuccess()
          setTimeout(()=>{
            console.log('once')
            data.country = 'nigeria'
            callback(data, dispatch)
          }, 500)
       
      }else{
        setIsSubmitting(false)
      }

     
    },
    [errors,callback,isSubmitting, data, dispatch]
  );

  return { handleChange, handleSubmit, errors, isSubmitting};
};


export default useForm;
