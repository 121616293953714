import { Form, FormItems, ButtonItems, Button } from './tabItems.styled'
import Divider from '../../divider/Divider'
import { SelectItems, InputItems} from './tabItemsComponents'

//SelectItems Options Data
const item1 = [{id: 1, label: "coll1" }, {id: 2, label: "coll1" }, {id: 3, label: "coll1" }]
const item2 = [{id: 1, label: "new"}, {id: 2, label: "new"}, {id: 3, label: "new"}]

//User Form
const UserDefault = () => {
  return (
    <Form enlarge="40">
       <Divider title="Regional Setting" width="52"/>
       <FormItems>
         <SelectItems 
            title="User Interface Language" 
            data={item1} 
            placeText="User Interface Language" 
            id="id" 
            label="label"
         icon/>
         <InputItems title="Billing Currency for users" placeText="Enter Billing Currency for users" icon/>
         <SelectItems 
            title="Time Zone" 
            data={item2} 
            placeText="Enter Time Zone" 
            id="id" 
            label="label"
            icon/>
         <InputItems title="Date Format" placeText="DD/MM/YYYY" icon bottom/>
       </FormItems>
       <ButtonItems mt="5">
             <Button bgColor="838191">Reset</Button>
             <Button bgColor="4D9200">Save</Button>
        </ButtonItems>
    </Form>
  )
}

export default UserDefault